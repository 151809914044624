import React from 'react'
import { useState } from 'react'
import { selectedGeos } from '@utils/GeoUtils'
import modules from '@modules'
import { Typography, Tag, Button } from 'antd'
import useActions from '@hooks/useActions'

import './EpsilonSelectedFilterBar.less'
import useAccount from '@hooks/useAccount'
import { selectedCriticalMoments } from '@utils/CriticalMomentUtils'
import useClientConfig from 'config/clients/useClientConfig'
import { selectedLogicals } from '@utils/LogicalUtils'

type PropsType = {
  filtersData: Record<string, any>
  filters: FiltersUI
}


/**
 * FilterBar
 */
export const EpsilonSelectedFilterBar = ({ filters, filtersData, }: PropsType): JSX.Element => {
  const dispatchers = useActions(modules.actions.appData, [])
  const { userProfiles } = useAccount()
  const [rerender, setRerender] = useState<number>(1)

  const {
    includesFilterGeo,
    includesFilterCriticalMoment,
    getGeosRootId,
    getLogicalsRootId,
    getFilterCriticalMomentDefault,
    getCriticalMomentsRootId,
    includesFilterLogical
  } = useClientConfig()

  const geos = selectedGeos(filtersData.geographicDistributions, filters.geographicDistributions)
  const logicals = selectedLogicals(filtersData.logicalDistributions, filters.logicalDistributions)
  const cms = selectedCriticalMoments(filtersData.criticalMoments, filters.criticalMoments)

  React.useEffect(() => {
    setRerender(rerender + 1)
  }, [filters,])

  const handleDeleteGeo = (geo: number) => {
    let selectedGeos = filters.geographicDistributions
      .filter(item => item !== geo)

    if (selectedGeos.length === 0) {
      selectedGeos = [filtersData.geographicDistributions[0].id]
    }

    dispatchers.setFilters({
      ...filters,
      geographicDistributions: selectedGeos
    })
  }

  const handleDeleteLogical = (logical: number) => {
    let selectedLogicals = filters.logicalDistributions
      .filter(item => item !== logical)

    if (selectedLogicals.length === 0) {
      selectedLogicals = [filtersData.logicalDistributions[0].id]
    }

    dispatchers.setFilters({
      ...filters,
      logicalDistributions: selectedLogicals
    })
  }

  const handleDeleteCriticalMoment = (cm: number) => {
    //console.log('Elimino selected critical Moment ' + cm)
    let selectedCms = filters.criticalMoments
      .filter(item => item !== cm)

    //console.log(selectedCms)
    //console.log(getFilterCriticalMomentDefault(filtersData, userProfiles))

    if (selectedCms.length === 0) {
      //selectedCms = [filtersData.criticalMoments[0].id]
      selectedCms = [getFilterCriticalMomentDefault(filtersData, userProfiles)]
    }

    dispatchers.setFilters({
      ...filters,
      criticalMoments: selectedCms
    })
  }

  const geosFilters = includesFilterGeo()
    && geos.some(geo => geo.id !== getGeosRootId())

  const logicalsFilters = includesFilterLogical()
    && logicals.some(logical => logical.id !== getLogicalsRootId())

  const cmsFilters = includesFilterCriticalMoment()
    && cms.some(cm => cm.id !== getCriticalMomentsRootId())

  const showBar = geosFilters || logicalsFilters || cmsFilters


  //console.log('Rendering selected filters bar')
  //console.log(filters)
  //console.log(getGeosRootId())
  //console.log(geosFilters)
  //console.log(cmsFilters)

  return (
    <div className='selected-filter-bar-component'>
      {showBar && (
        <Typography className='title-selected-filters-bar-component'>Filtros:</Typography>
      )}
      {geosFilters && geos
        .filter(geo => geo.id !== getGeosRootId())
        .map((geo: any, idx: number) => {
          return (
            <Tag
              key={'selected-geo-' + geo.id}
              closable
              onClose={() => handleDeleteGeo(geo.id)}
              className='tag-style-filters-bar-component'
            >{geo.description}</Tag>
          )
        })}
      {logicalsFilters && logicals
        .filter(logical => logical.id !== getGeosRootId())
        .map((logical: any, idx: number) => {
          return (
            <Tag
              key={'selected-logical-' + logical.id}
              closable
              onClose={() => handleDeleteLogical(logical.id)}
              className='tag-style-filters-bar-component'
            >{logical.description}</Tag>
          )
        })}
      {cmsFilters && cms
        .filter(cm => cm.id !== getCriticalMomentsRootId())
        .map((cm: any, idx: number) => {
          return (
            <Tag
              key={'selected-cm-' + cm.id}
              closable
              onClose={() => handleDeleteCriticalMoment(cm.id)}
              className='tag-style-filters-bar-component'
            >{cm.description}</Tag>
          )
        })}
    </div>
  )
}
