import Immutable from 'seamless-immutable'
import { pieColors } from '../dashHelper'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'
import { getLeavesByField } from '@utils/GeoUtils'

const QUARTER_KEYS = {
  'durante_a_pregunta_13': 'Alimentos',
  'durante_a_pregunta_33': 'Trato', 
  'durante_a_pregunta_11': 'Atención telefónica', 
  'durante_a_pregunta_24': 'Trato enferemeria', 
  'durante_a_pregunta_8': 'Instalaciones', 
}

export const haTrendDataMapper = (data: any, extra?: any) => {
  if (data === undefined || data.length === 0) {
    return data
  }

  const geoLeaves: string[] = getLeavesByField(extra.geoLocations[0], 'name')

  const root = data.filter((item: any) => item.group === null)

  const regions = Immutable.asMutable(data)
    .filter((item: any) => item.group !== null && !geoLeaves.includes(item.group))
    .sort((item1: any, item2: any) => item2.value - item1.value)

  const leaves = Immutable.asMutable(data)
    .filter((item: any) => geoLeaves.includes(item.group))
    .sort((item1: any, item2: any) => item2.value - item1.value)

  const finalData = [
    ...root,
    ...regions,
    ...leaves
  ].slice(0, 5)

  return finalData.reverse()
}

export const COLORS_CONFIG = [
  {
    color: '#F42313',
    condition: '<=' as const,
    key: 'value',
    value: 3,
  },
  {
    color: '#FAEF6B',
    condition: '>' as const,
    key: 'value',
    value: 3,
  },
  {
    color: '#6DE3B4',
    condition: '>' as const,
    key: 'value',
    value: 4,
  },
]

export const COLORS_CONFIG_BAR = [
  {
    color: '#F42313',
    condition: '<=' as const,
    key: 'value',
    value: 6,
  },
  {
    color: '#FAEF6B',
    condition: '>' as const,
    key: 'value',
    value: 6,
  },
  {
    color: '#6DE3B4',
    condition: '>' as const,
    key: 'value',
    value: 8,
  },
]

export const yesNoMapperHa = (data: any) => {
  if (data === undefined || data.length === 0) {
    return data
  }

  return data.map((item: any) => ({
    ...item,
    groupName: item.group === '0' ? 'No' : 'Si',
    label: item.group === '0' ? 'No' : 'Si'
  }))
}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  customLineDetailedByMonthAndGeo: {
    title: 'Satisfacción General',
    helpText: 'Satisfacción General',
    helpLink: '--',
    // [Column, Row]
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'line-detailed-by-month-and-geo',
      type: 'lineDetailed',
      //fillMode: 'gradient',
      fillMode: '',
      colors: pieColors,
      showValues: false,
      //percentageScale: false,
      shadow: false,
      mapper: (data: any) => {
        //console.log('Rendering line-detailed-by-month-and-geo')
        //console.log(data)
        const metrics = [
          { label: 'NPS General', value: data['nps-gral'] },
          ...data['nps-by-critical-moment'].map((item: any) => ({
            label: item.group,
            value: item.value
          })),
          ...data['nps-by-geo'].map((item: any) => ({
            label: item.group,
            value: item.value
          }))
        ]

        return {
          value: data['nps-by-month'].map((item: any, idx: number) => ({
            ...item,
            id: idx,
            category: item.groupName ? item.groupName : item['group_0'],
            group: item['group_1'] ? item['group_1'] : item.label,
          })),
          metrics: metrics
        }
      },
      indicators: [
        {
          key: 'nps-by-month',
          indicator: 'NPS_GENERAL',
          grouped: 'formattedMonth,geoLocation',
          dataType: 'single',
          keyExtract: [
            'group_0',
            'group_1',
            'groupId_0',
            'value',
            'groupName'
          ],
          label: 'group',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
        {
          key: 'nps-gral',
          indicator: 'NPS_GENERAL',
          keyExtract: ['value'],
          value: 'value',
          dataType: 'single',
          singleton: false,
          extras: {},
        },
        {
          key: 'nps-by-critical-moment',
          indicator: 'NPS_GENERAL',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'criticalMoment',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
        {
          key: 'nps-by-geo',
          indicator: 'NPS_GENERAL',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
      ],
    },
  },
  haTrendByGeo: {
    title: 'FALTA',
    helpText: 'FALTA',
    helpLink: '--',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'generic-trend-by-geo',
      type: 'trend',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: true,
      showTrend: false,
      decimals: 2,
      multiIndicator: true,
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'geoLocation',
          keyExtract: ['value', 'groupName', 'count', 'group'],
          value: 'value',
          historic: false,
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            featureInclude: ['zoomOut'],
            //featureInclude: ['zoomOut', 'groupBySelfInclude'],
            //maxGroupByLevel: 2
          },
        }
      ],
    },
  },
  quarterTrend: {
    title: 'FALTA',
    helpText: 'FALTA',
    helpLink: '--',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'quarter-trend',
      type: 'quarter',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: false,
      showTrend: false,
      decimals: 2,
      axisYTitleMarginTop: '100%',
      cellType: 'bubble',
      categories: ['¿Cómo lo hacemos?', 'Impacto en la satisfacción del cliente'],
      mapper: (data: any) => {

        const correlaciones = data['correlaciones']
        const valores = data['valores']

        var legends = {}
        var tooltips = {}
        var finalData = {}
        correlaciones.map((correlacion: any, idx: number) => {
          const groupName = correlacion.groupName
          const key = correlacion.group
          var valueY = correlacion['value_norm']
          var valueX = valores.find((item: any) => item.groupId === key)?.value

          const legendSplit = correlacion.groupName.split('_')

          tooltips[groupName] = correlacion.description
          legends[groupName] = [QUARTER_KEYS[groupName], valueY.toFixed(1) + '%']

          valueY = valueY < 33 ? 1 : (valueY > 66 ? 3 : 2)
          valueX = valueX < 33 ? 1 : (valueX > 66 ? 3 : 2)
          finalData[groupName] = [valueX, valueY]
        })

        return {
          values: finalData,
          tooltips: tooltips,
          legends: legends
        }
      },
      indicators: [
        {
          indicator: 'CORRELATION',
          key: 'correlaciones',
          dataType: 'list',
          keyExtract: [
            'groupName', 'count', 'description',
            'value', 'group', 'value_norm'
          ],
          value: 'value',
          historic: false,
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            correlationTarget: 10879,
            sort: "asc",
            normalize: true,
            normalize_a: "25",
            normalize_b: "75"
          },
        },
        {
          indicator: 'CORRELATION_VALUES',
          key: 'valores',
          grouped: 'question',
          dataType: 'list',
          keyExtract: [
            'fanCount', 'detractorCount',
            'count', 'passiveCount',
            'promoterCount', 'group',
            'value', 'promoters', 'detractors',
            'fans', 'passives', 'promoterNoFanCount',
            'promotersNoFan', 'groupId'
          ],
          value: 'value',
          historic: false,
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {},
        }
      ],
    },
  },
}

