import { answeredMapper, getCommonWidget, invalidMapper } from '../dashHelper'
import { COLORS_CONFIG, commonWidgets } from './commonWidgetsMitsu'
export default {
  title: 'Distribuidor Dashboard',
  description: 'Dashboard de Distribuidor',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      position: [0, 0],
      size: [1, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      position: [1, 0],
      size: [1, 2],
      indicator: 'POSVENTA_NPS',
      criticalMomentId: 2,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 0],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 0],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      mapper: answeredMapper,
      // [Column, Row]
      position: [3, 0],
      size: [1, 2],
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      position: [0, 2],
      size: [4, 2],
      indicator: 'POSVENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasPostventa',
      position: [0, 4],
      size: [2, 2],
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasPostventa',
      position: [2, 4],
      size: [2, 2],
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsPostventa',
      position: [0, 6],
      size: [2, 2],
      indicator: 'sentiment',
      criticalMomentId: 2,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsPostventa',
      position: [2, 6],
      size: [2, 2],
      indicator: 'sentiment',
      criticalMomentId: 3,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    {
      ...commonWidgets['textAnalysis'],
      position: [0, 8],
      size: [4, 4],
    },
    {
      title: 'widgets.rankingZoneDistribuidores',
      helpText: 'widgets.rankingZoneDistribuidores',
      position: [0, 12],
      size: [2, 1],
      config: {
        id: 'posventa-ranking-zonas',
        type: 'rankingIPN',
        /*mapper: (data: any) => {
          return data.filter((item: any) => item.id == 1)
        },*/
        indicators: [
          {
            indicator: 'POSVENTA_SATISFACCION',
            keyExtract: ['group', 'position', 'groupCount'],
            grouped: 'geoLocation',
            label: 'group',
            value: 'position',
            singleton: true,
            sort: { value: 'desc' },
            //limit: 1,
            extras: {
              aggregation: 'rankingPosition',
              rankingPositionScope: 0,
              //featureInclude: ['zoomOut'],
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.rankingNationalDistribuidores',
      helpText: 'widgets.rankingNationalDistribuidores',
      position: [2, 12],
      size: [2, 1],
      config: {
        id: 'posventa-ranking-nacional',
        type: 'rankingIPN',
        indicators: [
          {
            indicator: 'POSVENTA_SATISFACCION',
            keyExtract: ['group', 'position', 'groupCount'],
            grouped: 'geoLocation',
            label: 'group',
            value: 'position',
            singleton: true,
            sort: { value: 'desc' },
            limit: 1,
            extras: {
              aggregation: 'rankingPosition',
              rankingPositionScope: 100,
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.otherIndicators',
      type: 'label',
      position: [0, 13],
      size: [4, 1],
    },
    {
      title: 'widgets.appointmentPostventa',
      helpText: 'widgets.appointmentPostventa',
      // [Column, Row]
      position: [0, 14],
      size: [2, 2],
      config: {
        id: 'posventa-puntualidad',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        //percentageScale: false,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'POSVENTA_AGENDAR_CITA',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.waitingRoomPostventa',
      helpText: 'widgets.waitingRoomPostventa',
      // [Column, Row]
      position: [2, 14],
      size: [1, 2],
      config: {
        id: 'posventa-instalaciones',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_INSTALACIONES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.signagePostventa',
      helpText: 'widgets.signagePostventa',
      // [Column, Row]
      position: [3, 14],
      size: [1, 2],
      config: {
        id: 'posventa-senaletica',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_SENIALECTICA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.paymentPostventa',
      helpText: 'widgets.paymentPostventa',
      // [Column, Row]
      position: [0, 16],
      size: [1, 2],
      config: {
        id: 'posventa-facturacion',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_FACTURACION',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.substitutionCarPostventa',
      helpText: 'widgets.substitutionCarPostventa',
      // [Column, Row]
      position: [1, 16],
      size: [1, 2],
      config: {
        id: 'posventa-vehiculo-sustitucion',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_VEHICULO_SUSTITUCION',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.carStatusPostventa',
      helpText: 'widgets.carStatusPostventa',
      // [Column, Row]
      position: [2, 16],
      size: [1, 2],
      config: {
        id: 'posventa-limpieza-vehiculo',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_LIMPIEZA_VEHICULO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.repairPostventa',
      helpText: 'widgets.repairPostventa',
      // [Column, Row]
      position: [3, 16],
      size: [1, 2],
      config: {
        id: 'posventa-reparacion',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_REPARACION_VEHICULO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.repairAvailablePostventa',
      helpText: 'widgets.repairAvailablePostventa',
      // [Column, Row]
      position: [0, 18],
      size: [1, 2],
      config: {
        id: 'posventa-disponibilidad',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_DISPONIBILIDAD_TALLER',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.budgetPostventa',
      helpText: 'widgets.budgetPostventa',
      // [Column, Row]
      position: [1, 18],
      size: [1, 2],
      config: {
        id: 'posventa-presupuesto',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_PRESUPUESTO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.warrantyPostventa',
      helpText: 'widgets.warrantyPostventa',
      // [Column, Row]
      position: [2, 18],
      size: [1, 2],
      config: {
        id: 'posventa-garantia',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_GARANTIA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
  ],
} as DasboardConfig
