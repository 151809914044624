import { answeredMapper, getCommonWidget, invalidMapper } from '../dashHelper'
import {
  COLORS_CONFIG,
  COLORS_CONFIG_BAR,
  commonWidgets,
} from './commonWidgetsByd'

export default {
  title: 'Zonal Postventa Dashboard',
  description: 'Dashboard de Zonal Postventa',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      position: [0, 0],
      size: [1, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      position: [1, 0],
      size: [1, 2],
      indicator: 'POSVENTA_NPS',
      criticalMomentId: 2,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 0],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 0],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      mapper: answeredMapper,
      // [Column, Row]
      position: [3, 0],
      size: [1, 2],
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      position: [0, 2],
      size: [4, 2],
      indicator: 'POSVENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasPostventa',
      position: [0, 4],
      size: [2, 2],
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasPostventa',
      position: [2, 4],
      size: [2, 2],
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [0, 6],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [2, 6],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsPostventa',
      position: [0, 8],
      size: [2, 2],
      indicator: 'sentiment',
      criticalMomentId: 2,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsPostventa',
      position: [2, 8],
      size: [2, 2],
      indicator: 'sentiment',
      criticalMomentId: 3,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    {
      ...commonWidgets['textAnalysis'],
      position: [0, 10],
      size: [4, 4],
    },
    {
      title: 'widgets.zoneGeneralSatisfaction',
      helpText: 'widgets.zoneGeneralSatisfaction',
      // [Column, Row]
      position: [0, 14],
      size: [4, 2],
      config: {
        id: 'satisfaction-zona',
        type: 'rankingIPN',
        indicators: [
          {
            indicator: 'SERVICIO_NPS',
            keyExtract: ['group', 'value', 'groupCount', 'position'],
            grouped: 'geoLocation',
            label: 'group',
            value: 'position',
            singleton: true,
            limit: 5,
            extras: {
              aggregation: 'rankingPosition',
              featureInclude: ['zoomOut'],
            },
          },
        ],
      },
    },
    {
      title: 'widgets.distributorGeneralSatisfaction',
      helpText: 'widgets.distributorGeneralSatisfaction',
      // [Column, Row]
      position: [0, 16],
      size: [4, 2],
      config: {
        id: 'satisfaccion-dist',
        type: 'bar',
        fillMode: '',
        //colors: '#f38625',
        colors: COLORS_CONFIG_BAR,
        showValues: true,
        percentageScale: false,
        indicators: [
          {
            indicator: 'SERVICIO_SATISFACCION',
            grouped: 'geoLocation',
            keyExtract: ['group', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupByLevel: 3,
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.appointmentPostventa',
      helpText: 'widgets.appointmentPostventa',
      // [Column, Row]
      position: [0, 18],
      size: [2, 2],
      config: {
        id: 'posventa-puntualidad',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        //percentageScale: false,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'SERVICIO_AGENDAR_CITA',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.waitingRoomPostventa',
      helpText: 'widgets.waitingRoomPostventa',
      // [Column, Row]
      position: [2, 18],
      size: [1, 2],
      config: {
        id: 'posventa-instalaciones',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'SERVICIO_INSTALACIONES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.signagePostventa',
      helpText: 'widgets.signagePostventa',
      // [Column, Row]
      position: [3, 18],
      size: [1, 2],
      config: {
        id: 'posventa-senaletica',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'SERVICIO_SENIALECTICA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.paymentPostventa',
      helpText: 'widgets.paymentPostventa',
      // [Column, Row]
      position: [0, 20],
      size: [1, 2],
      config: {
        id: 'posventa-facturacion',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'SERVICIO_FACTURACION',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.substitutionCarPostventa',
      helpText: 'widgets.substitutionCarPostventa',
      // [Column, Row]
      position: [1, 20],
      size: [1, 2],
      config: {
        id: 'posventa-vehiculo-sustitucion',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'SERVICIO_VEHICULO_SUSTITUCION',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.carStatusPostventa',
      helpText: 'widgets.carStatusPostventa',
      // [Column, Row]
      position: [2, 20],
      size: [1, 2],
      config: {
        id: 'posventa-limpieza-vehiculo',
        type: 'gauge',
        fillMode: '',
        //colors: ['#33b9ce', '#b2e7ec', '#dadada'],
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'SERVICIO_LIMPIEZA_VEHICULO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.repairPostventa',
      helpText: 'widgets.repairPostventa',
      // [Column, Row]
      position: [3, 20],
      size: [1, 2],
      config: {
        id: 'posventa-reparacion',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'SERVICIO_REPARACION_VEHICULO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.repairAvailablePostventa',
      helpText: 'widgets.repairAvailablePostventa',
      // [Column, Row]
      position: [0, 22],
      size: [1, 2],
      config: {
        id: 'posventa-disponibilidad',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'SERVICIO_DISPONIBILIDAD_TALLER',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.budgetPostventa',
      helpText: 'widgets.budgetPostventa',
      // [Column, Row]
      position: [1, 22],
      size: [1, 2],
      config: {
        id: 'posventa-presupuesto',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'SERVICIO_PRESUPUESTO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.warrantyPostventa',
      helpText: 'widgets.warrantyPostventa',
      // [Column, Row]
      position: [2, 22],
      size: [1, 2],
      config: {
        id: 'posventa-garantia',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'SERVICIO_GARANTIA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
  ],
} as DasboardConfig
