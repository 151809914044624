
import logoBydSmall from '@assets/logos/byd-logo-small.png'
import logoByd from '@assets/logos/byd-logo.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'

export const bydConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://byd-backend-voc.limetropy.com/api/',
    lcmBackendUrl: '',
    //backendUrl: 'http://localhost:9000/api/',
    publicUrl: undefined,
    logoSmall: logoBydSmall,
    logo: logoByd,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 1,
    supportsInternationalization: true,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT],
    msExcelReportExt: 'xls',
    geosRootId: 10001,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    omoikane: {
        enabledTabs: [],
        indicators: [],
        sentiment: {
            indicators: {},
            properties: {
                sentiment: '',
                percentage: '',
                sentimentLocal: '',
                percentageLocal: ''
            }
        },
        customerJourney: {},
        cxRay: { indicators: {} },
        clusters: {
            counters: { indicator: 'survey-count' },
            detail: {
                indicators: []
            }
        },
    },
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.byd,
        default: DASHBOARDS_CONFIGS.byd[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponsesUrl: 'survey-responses'
}
