import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const COLORS_CONFIG = [
  {
    color: '#F42313',
    condition: '<=' as const,
    key: 'value',
    value: 3,
  },
  {
    color: '#FAEF6B',
    condition: '>' as const,
    key: 'value',
    value: 3,
  },
  {
    color: '#6DE3B4',
    condition: '>' as const,
    key: 'value',
    value: 4,
  },
]

export const COLORS_CONFIG_BAR = [
  {
    color: '#F42313',
    condition: '<=' as const,
    key: 'value',
    value: 6,
  },
  {
    color: '#FAEF6B',
    condition: '>' as const,
    key: 'value',
    value: 6,
  },
  {
    color: '#6DE3B4',
    condition: '>' as const,
    key: 'value',
    value: 8,
  },
]

export const getCmID = (code: string): number => {
  if (code === 'venta') return 10651
  else if (code === 'servicio' || code === 'postventa') return 10652
  else return 1
}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
}
