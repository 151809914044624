import { getCommonWidget, renderForCriticalMoment, renderForGeoLocation } from '../dashHelper'
import { commonWidgets } from './commonWidgetsHa'
import Immutable from 'seamless-immutable'

const allWidgets = [
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'customLineDetailedByMonthAndGeo',
    title: 'NPS General',
    position: [0, 0],
    size: [4, 3],
    extraConfigs: {
      backgroundColor: 'transparent',
      theme: 'dark',
    },
    indicator: 'NPS_GENERAL',
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'lineDetailedByMonthAndGeo',
    title: 'NPS General',
    position: [0, 0],
    size: [4, 3],
    extraConfigs: {
      backgroundColor: 'transparent',
      theme: 'dark',
    },
    indicator: 'NPS_GENERAL',
  }),
  {
    title: 'Análisis de datos',
    type: 'label',
    position: [0, 3],
    size: [4, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    title: 'Ranking NPS mejores hospitales',
    name: 'barByGeo',
    position: [0, 4],
    size: [4, 2],
    indicator: 'NPS_GENERAL',
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'desc' },
      limit: 6
    },
    extraConfigs: {
      wrapTexts: true
    },
    extras: {
      groupByLevel: 2
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    title: 'Ranking NPS hospitales a mejorar',
    name: 'barByGeo',
    position: [0, 6],
    size: [4, 2],
    indicator: 'NPS_GENERAL',
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'asc' },
      limit: 6
    },
    extraConfigs: {
      wrapTexts: true
    },
    extras: {
      groupByLevel: 2
    },
  }),
  {
    title: 'Matriz de Priorización',
    type: 'label',
    position: [0, 8],
    size: [4, 1],
    criticalMomentInclude: [10301]
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'quarterTrend',
    position: [0, 9],
    size: [4, 3],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    position: [0, 12],
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'diagramaIshikawa',
    title: 'Diagrama Ishikawa',
    position: [0, 15],
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    position: [0, 18],
    size: [4, 2]
  }),
]

export default {
  title: 'Default Dashboard',
  description: 'Dashboard de perfil Default',
  widgets: (filters: FiltersUI) => {
    const isCmRoot = renderForCriticalMoment(filters, [29])
    const isGeoRoot = renderForGeoLocation(filters, [0])

    return allWidgets.filter((wdg: any) => {
      return (isCmRoot && isGeoRoot && wdg.widgetName === 'customLineDetailedByMonthAndGeo')
        || (isCmRoot && !isGeoRoot && wdg.widgetName === 'lineDetailedByMonthAndGeo')
        || (wdg.widgetName !== 'lineDetailedByMonthAndGeo'
          && wdg.widgetName !== 'customLineDetailedByMonthAndGeo')
    })
  },
} as DasboardConfig
