import cxoResume from './cxoResume'
//import nacionalPosventa from './nacionalPosventa'
//import nacionalVenta from './nacionalVenta'

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  widgets: (filters: FiltersUI) => [

    ...cxoResume.widgets(filters).map((wdg: any) => ({
      ...wdg,
     // render: (filters: FiltersUI) => true,
      /*
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(0),
      */
    })),

    /*
    ...nacionalVenta.widgets.map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(1),
    })),

    ...nacionalPosventa.widgets.map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(2),
    })),
    */

  ],
} as DasboardConfig
