import { getCommonWidget } from "../dashHelper"
import { commonWidgets } from "./commonWidgetsIamsa"

export default {
  title: 'Marketing Dashboard',
  description: 'Dashboard de Director de Marketing',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) =>[
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'NPS',
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'nps',
      key: 'nps-general'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'xgGauge',
      position: [0, 3],
      size: [2, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'xmGauge',
      position: [2, 3],
      size: [1, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'xmcGauge',
      position: [3, 3],
      size: [1, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'xgmcExperience',
      position: [0, 5],
      size: [4, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'Felicidades! Lo que mejor hacemos',
      indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
      position: [0, 7],
      size: [2, 1],
      extraConfigs: {
        limit: 1,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'Nuestro Reto. Lo que vamos a mejorar',
      indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
      position: [2, 7],
      size: [2, 1],
      extraConfigs: {
        limit: 1,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      key: 'best-offices',
      title: 'Mejores oficinas',
      position: [0, 8],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      key: 'worst-offices',
      title: 'Oficinas a mejorar',
      position: [1, 8],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBestAndTopLocation',
      key: 'best-zones',
      title: 'Mejores divisiones',
      position: [2, 8],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
        rankingType: 'box',
      },
      indicator: 'SATISFACCION_GENERAL'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorstAndTopLocation',
      key: 'worst-zones',
      title: 'Divisiones a mejorar',
      position: [3, 8],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
        rankingType: 'box',
      },
      indicator: 'SATISFACCION_GENERAL',
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 10],
      size: [4, 4],
      indicator: 'SENTIMENT',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawaByLogical',
      title: 'Diagrama Ishikawa',
      position: [0, 14],
      size: [4, 3],
      indicator: 'SENTIMENT'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lastComments',
      indicator: 'comments',
      position: [0, 17],
      size: [4, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stmcExperience',
      position: [0, 19],
      size: [4, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'xgmExperience',
      position: [0, 21],
      size: [4, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'xgbExperience',
      position: [0, 23],
      size: [4, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'xgmcdExperience',
      position: [0, 25],
      size: [4, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'xgmccExperience',
      position: [0, 27],
      size: [4, 2],
    }),
  ],
} as DasboardConfig
