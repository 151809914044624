
import logoHa from '@assets/logos/ha-logo.png'
import logoHaSmall from '@assets/logos/ha-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments, getMitsubishiFilterCriticalMomentDefault, getMitsubishiFilterCriticalMoments } from '../dashboard/dashHelper'

export const haConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://ha-backend-voc.limetropy.com/api/',
    lcmBackendUrl: '',
    publicUrl: '/voc',
    logoSmall: logoHaSmall,
    logo: logoHa,
    filtersFromDateSubstract: 1,
    filtersToDateSubstract: 0,
    supportsInternationalization: false,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT],
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    msExcelReportExt: 'xls',
    geosRootId: 0,
    logicalsRootId: 0,
    criticalMomentsRootId: 29,
    criticalMomentFilterType: 'select',
    omoikane: {
        enabledTabs: [],
        indicators: [],
        sentiment: {
            indicators: {},
            properties: {
                sentiment: '',
                percentage: '',
                sentimentLocal: '',
                percentageLocal: ''
            }
        },
        customerJourney: {},
        cxRay: { indicators: {} },
        clusters: {
            counters: { indicator: 'survey-count' },
            detail: {
                indicators: []
            }
        },
    },
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.ha,
        default: DASHBOARDS_CONFIGS.ha[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponsesUrl: 'survey-responses'
}
