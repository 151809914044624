
import logoIamsaSmall from '@assets/logos/iamsa-logo-small.png'
import logoIamsa from '@assets/logos/iamsa-logo.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { omoikaneIamsa } from '../omoikane/omoikaneIamsa'

export const iamsaConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://iamsa-delta.limetropy.com/api/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    lcmBackendUrl: 'https://iamsa-delta.limetropy.com/backend/core/api/ui/',
    publicUrl: undefined,
    logoSmall: logoIamsaSmall,
    logo: logoIamsa,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 0,
    supportsInternationalization: false,
    filters: [
        FILTER_TYPES.DATES, 
        FILTER_TYPES.GEOS, 
        //FILTER_TYPES.CRITICAL_MOMENTS, 
        FILTER_TYPES.LOGICALS
    ],
    menuDashboards: [DASHBOARDS.DEFAULT],
    msExcelReportExt: 'xls',
    geosRootId: 214,
    logicalsRootId: 15,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    omoikane: omoikaneIamsa,
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        //METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.LOGICALS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS,
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.iamsa,
        default: DASHBOARDS_CONFIGS.iamsa[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponsesUrl: 'survey-responses-old'
}
