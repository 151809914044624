
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const COLORS_CONFIG = [
  {
    color: '#F42313',
    condition: '<=' as const,
    key: 'value',
    value: 3,
  },
  {
    color: '#FAEF6B',
    condition: '>' as const,
    key: 'value',
    value: 3,
  },
  {
    color: '#6DE3B4',
    condition: '>' as const,
    key: 'value',
    value: 4,
  },
]

export const COLORS_CONFIG_BAR = [
  {
    color: '#F42313',
    condition: '<=' as const,
    key: 'value',
    value: 6,
  },
  {
    color: '#FAEF6B',
    condition: '>' as const,
    key: 'value',
    value: 6,
  },
  {
    color: '#6DE3B4',
    condition: '>' as const,
    key: 'value',
    value: 8,
  },
]

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  textAnalysis: {
    title: 'Análisis de sentimientos',
    helpText:
      'Todos los comentarios de los clientes se distribuyen en categorías y etiquetas y se hace una valoración automática que los agrupa en Negativos, Neutros y Positivos. De un vistazo, podemos observar esa agrupación por colores. En el cuadro de la derecha se obtiene más información de los comentarios pinchando en la categoría o etiqueta. Este gráfico es dinámico con la selección del análisis de texto.',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'text-analysis-qr',
      type: 'textAnalysis',
      indicators: [
        {
          indicator: 'SENTIMENT',
          grouped: 'tag',
          keyExtract: ['categories', 'count'],
          singleton: false,
          value: 'value',
          extras: {},
        },
      ],
    },
  },
  genericTrend: {
    title: 'NPS',
    helpText: 'NPS',
    helpLink: '--',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'nps-trend',
      type: 'trend',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: true,
      showTrend: true,
      decimals: 2,
      multiIndicator: true,
      indicators: [
        {
          indicator: 'durante_a_pregunta_6',
          grouped: 'formattedMonth',
          keyExtract: ['value', 'groupName', 'count', 'group'],
          value: 'value',
          historic: false,
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            featureInclude: ['zoomOut']
          },
        }
      ],
    },
  },
}

