

export const widgetsRanking: CommonWidget = {


  /**************************************************************************
   *                                RANKINGS
  ***************************************************************************/

  rankingByGeoBest: {
    title: 'Top 5 Mejores estaciones',
    helpText: 'Top 5 Mejores estaciones',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-geo-best',
      type: 'ranking',
      showSample: false,
      indicators: [
        {
          indicator: 'satisfaction',
          keyExtract: ['group', 'value', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'desc' },
          limit: 6,
          extras: {
            featureExclude: ['groupByTopLocation']
          },
        },
      ],
    },
  },

  rankingByGeoBestAndTopLocation: {
    title: 'Top 5 Mejores estaciones',
    helpText: 'Top 5 Mejores estaciones',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-geo-best',
      type: 'ranking',
      showSample: false,
      indicators: [
        {
          indicator: 'satisfaction',
          keyExtract: ['group', 'value', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'desc' },
          limit: 6,
          extras: {},
        },
      ],
    },
  },

  rankingByGeoWorst: {
    title: 'Top 5 Estaciones a mejorar',
    helpText: 'Top 5 Estaciones a mejorar',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-geo-worst',
      type: 'ranking',
      showSample: false,
      indicators: [
        {
          indicator: 'satisfaction',
          keyExtract: ['group', 'value', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'asc' },
          limit: 6,
          extras: {
            featureExclude: ['groupByTopLocation']
          },
        },
      ],
    },
  },

  rankingByGeoWorstAndTopLocation: {
    title: 'Top 5 Estaciones a mejorar',
    helpText: 'Top 5 Estaciones a mejorar',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-geo-worst',
      type: 'ranking',
      showSample: false,
      indicators: [
        {
          indicator: 'satisfaction',
          keyExtract: ['group', 'value', 'count'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'asc' },
          limit: 6,
          extras: {},
        },
      ],
    },
  },

  rankingByTagBest: {
    title: 'Sentimiento positivo con más frecuencia',
    helpText: 'Sentimiento positivo con más frecuencia.',
    helpLink: '--',
    position: [0, 18],
    size: [2, 1],
    config: {
      id: 'ranking-by-tag-best',
      type: 'ranking',
      color: '#019E85',
      indicators: [
        {
          indicator: 'sentiment',
          keyExtract: ['groupName', 'positive', 'count'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { value: 'desc' },
          limit: 1,
          extras: {
            resultType: 'top'
          },
        },
      ],
    },
  },

  rankingByTagWorst: {
    title: 'Sentimiento negativo con más frecuencia',
    helpText: 'Sentimiento negativo con más frecuencia.',
    helpLink: '--',
    position: [2, 18],
    size: [2, 1],
    config: {
      id: 'ranking-by-tag-worst',
      type: 'ranking',
      color: '#DB7476',
      indicators: [
        {
          indicator: 'sentiment',
          keyExtract: ['groupName', 'positive', 'count'],
          label: 'groupName',
          //'value': 'positive',
          singleton: true,
          grouped: 'tag',
          sort: { positive: 'desc' },
          limit: 1,
          extras: {
            resultType: 'bottom'
          },
        },
      ],
    },
  },
  
  rankingByQuestionBest: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-question-best',
      type: 'ranking',
      fontSize: '30px',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          historic: false,
          grouped: 'question',
          sort: { value: 'desc' },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation'],
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  rankingByQuestionWorst: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'ranking-by-question-worst',
      type: 'ranking',
      fontSize: '30px',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'question',
          sort: { value: 'asc' },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation'],
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
}
