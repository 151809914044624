import { getCommonWidget, invalidMapper, answeredMapper } from "../dashHelper";
import { commonWidgets } from "./commonWidgetsCaffenio";

export default {
  title: 'Satisfacción Dashboard',
  description: 'Satisfacción Default',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: "Satisfacción General",
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'satisfaccion_pregunta_3',
      criticalMomentId: 10601
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Satisfacción General',
      position: [0, 3],
      size: [1, 2],
      indicator: 'satisfaccion_pregunta_3',
      criticalMomentId: 10601,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Recomedación',
      position: [1, 3],
      size: [1, 2],
      indicator: 'satisfaccion_pregunta_6',
      criticalMomentId: 10601,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Recompra',
      position: [2, 3],
      size: [1, 2],
      indicator: 'satisfaccion_pregunta_7',
      criticalMomentId: 10601,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: '% Registros válidos para envío de encuestas',
      position: [3, 3],
      size: [1, 2],
      criticalMomentId: 10601,
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: '% tasa de respuesta',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [0, 5],
      size: [1, 2],
      criticalMomentId: 10601,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa puntos de ventas',
      position: [1, 5],
      size: [2, 2],
      criticalMomentId: 10601,
      indicator: 'satisfaccion_pregunta_3',
    }),
    {
      title: "Productos",
      helpLink: "--",
      helpText: "Productos",
      position: [2, 7],
      size: [1, 2],
      config: {
        id: "satisfaccion_pregunta_1",
        type: "pie",
        colors: ["#015c52", "#33b9ce", "#f38625", "#8bde26", "#f9b50b"],
        showHistoric: false,
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 50,
        marginRight: 0,
        legendTop: 'top',
        itemDirection: "left-to-right",
        direction: "column",
        valueFormat: " >-.0%",
        center: ['50%', '65%'],
        radius: [25, 60],
        indicators: [
          {
            indicator: "satisfaccion_pregunta_1",
            keyExtract: ["group", "count", "value"],
            label: "group",
            value: "value",
            singleton: true,
            historic: false,
            grouped: "logicalLocation",
            extras: {
              useQuestionOptions: true,
              groupByLevel: 1
            }
          }
        ]
      }
    },
    {
      title: "Programa MiCAFFENIO",
      helpLink: "--",
      helpText: "Programa MiCAFFENIO",
      position: [3, 7],
      size: [1, 2],
      config: {
        id: "satisfaccion_pregunta_11",
        type: "pie",
        colors: ["#f38625", "#8bde26", "#f9b50b"],
        showHistoric: false,
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 50,
        marginRight: 0,
        legendTop: 'top',
        itemDirection: "left-to-right",
        direction: "column",
        valueFormat: " >-.0%",
        center: ['50%', '65%'],
        radius: [25, 60],
        indicators: [
          {
            indicator: "satisfaccion_pregunta_11",
            keyExtract: ["group", "count", "value"],
            label: "group",
            value: "value",
            singleton: true,
            historic: false,
            extras: {
              useQuestionOptions: true,
              groupByLevel: 1
            }
          }
        ]
      }
    },

    {
      title: "Verbalización",
      helpText: "Presenta en tiempo real aquello que los clientes nos comparten como sus experiencias más recientes.",
      helpLink: "--",
      position: [0, 7],
      size: [2, 2],
      config: {
        id: "word-count",
        type: "wordCloud",
        indicators: [
          {
            indicator: "WORD_COUNT",
            keyExtract: ["count", "value", "group"],
            singleton: true
          }
        ]
      }
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Analisis de Sentimientos',
      position: [0, 9],
      size: [4, 4],
      indicator: "SENTIMENT",
      criticalMomentId: 10601
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 13],
      size: [4, 2],
      criticalMomentId: 10601,
    }),
  ]
}
