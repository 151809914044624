import { ApisauceInstance } from 'apisauce'

import oxxo from './../mockData/oxxo.json'
import vsoxxo from './../mockData/vsoxxo.json'
import wordCount from './../mockData/wordCount.json'

type SocialInfoResponse = {
  data: SocialInfoData
  ok: boolean
}

type WordCountResponse = {
  data: WordCount[]
  ok: boolean
}

const wordCountResponse: WordCountResponse = {
  data: wordCount,
  ok: true,
}

const socialInfoResponse: SocialInfoResponse = {
  data: { oxxo: oxxo, vsoxxo: vsoxxo },
  ok: true,
}

export default <T>(api: ApisauceInstance): ApiObject => ({
  wordCount: (filters: FiltersUI) => {
    return new Promise<any>((resolve, reject) => {
      setTimeout(() => resolve(wordCountResponse), 1500)
    })
  },
  socialInfo: (filters: FiltersUI) => {
    return new Promise<any>((resolve, reject) => {
      setTimeout(() => resolve(socialInfoResponse), 1500)
    })
  },
})
