import { isEmpty } from 'lodash'
import { SECTION_KEYS } from './perms'
import icons from '@utils/icons'
import { ReactNode } from 'react'

export const PAGE_NAMES = {
  // Super Admin default page
  COMPANIES: 'COMPANIES',

  // Admin user pages
  SURVEYS: 'SURVEYS',
  LOGICAL_DISTRIBUTIONS: 'LOGICAL_DISTRIBUTIONS',
  GEOGRAPHIC_DISTRIBUTIONS: 'GEOGRAPHIC_DISTRIBUTIONS',
  CRITICAL_MOMENTS: 'CRITICAL_MOMENTS',
  ROLES: 'ROLES',
  DASHBOARD_BUILDER: 'DASHBOARD_BUILDER',
  USERS: 'USERS',

  // Normal user pages
  DASHBOARD: 'DASHBOARD',
  SURVEY_STATS: 'SURVEY_STATS',
  SOCIAL_LISTENING: 'SOCIAL_LISTENING',
  ALERTS_STATS: 'ALERTS_STATS',
  ALERTS_LIST: 'ALERTS_LIST',
  ACTION_PLANS: 'ACTION_PLANS',
  REPORTS: 'REPORTS',
  EXTENDED_DASHBOARD: 'EXTENDED_DASHBOARD',
  //DASHBOARD_QR: 'DASHBOARD_QR',
  //DASHBOARD_DISEL: 'DASHBOARD_DISEL',
  OMOIKANE_AI: 'OMOIKANE_AI',
  POWER_INTELLIGENCE: 'POWER_INTELLIGENCE',
  // DASHBOARDAI: 'DASHBOARDAI',
  // DASHBOARDNEW: 'DASHBOARDNF',
  // EMPLOYEE_EXP: 'EMPLOYEE_EXP',
  // SUPLIER_EXP: 'SUPLIER_EXP',
  //CUSTOMER_JOURNEYS: 'CUSTOMER_JOURNEYS',
}

type PageMenu = {
  icon: string | ReactNode
  label: string
  path: string
  key: string
  access: string
  action?: string
  order: number
}

export const PAGES_CONFIG: Record<string, PageMenu> = {
  [PAGE_NAMES.COMPANIES]: {
    icon: 'Shop',
    label: 'menu.Companies',
    path: '/admin/companies',
    key: 'companies',
    access: 'companies',
    order: 1,
  },

  [PAGE_NAMES.LOGICAL_DISTRIBUTIONS]: {
    icon: 'Apartment',
    label: 'menu.Logical_Distributions',
    path: '/admin/logical-distributions',
    key: SECTION_KEYS.LOGICAL_DISTRIBUTIONS,
    access: SECTION_KEYS.LOGICAL_DISTRIBUTIONS,
    order: 2,
  },

  [PAGE_NAMES.GEOGRAPHIC_DISTRIBUTIONS]: {
    icon: 'Environment',
    label: 'menu.Grographic_Distributions',
    path: '/admin/geo-distributions',
    key: SECTION_KEYS.GEOGRAPHIC_DISTRIBUTIONS,
    access: SECTION_KEYS.GEOGRAPHIC_DISTRIBUTIONS,
    order: 3,
  },

  [PAGE_NAMES.CRITICAL_MOMENTS]: {
    icon: 'Warning',
    label: 'menu.Critical_Moments',
    path: '/admin/critical-moments',
    key: SECTION_KEYS.CRITICAL_MOMENTS,
    access: SECTION_KEYS.CRITICAL_MOMENTS,
    order: 4,
  },

  [PAGE_NAMES.ROLES]: {
    icon: 'DeploymentUnit',
    label: 'menu.Roles',
    path: '/admin/roles',
    key: 'roles',
    access: 'roles',
    order: 5,
  },

  // [PAGE_NAMES.DASHBOARD_BUILDER]: {
  //   icon: 'FundView',
  //   label: 'menu.Dasboards',
  //   path: '/admin/dashboards',
  //   key: SECTION_KEYS.DASHBOARD_BUILDER,
  // },

  [PAGE_NAMES.USERS]: {
    icon: 'Team',
    label: 'menu.Users',
    path: '/admin/users',
    key: 'users',
    access: 'users',
    order: 6,
  },

  [PAGE_NAMES.SURVEYS]: {
    icon: icons.surveys,
    label: 'menu.Surveys',
    path: '/surveys',
    key: SECTION_KEYS.SURVEYS,
    access: SECTION_KEYS.SURVEYS,
    action: 'main',
    order: 7,
  },
  [PAGE_NAMES.DASHBOARD]: {
    icon: icons.dashboard,
    label: 'menu.Dashboard',
    path: '/dashboard',
    key: SECTION_KEYS.DASHBOARD,
    access: SECTION_KEYS.DASHBOARD,
    order: 1,
  },

  [PAGE_NAMES.EXTENDED_DASHBOARD]: {
    icon: 'AreaChart',
    label: 'menu.DashboardExtended',
    path: '/extended-dashboard',
    key: SECTION_KEYS.EXTENDED_DASHBOARD,
    access: SECTION_KEYS.EXTENDED_DASHBOARD,
    order: 2,
  },

  /*[PAGE_NAMES.DASHBOARD_QR]: {
    icon: 'AreaChart',
    label: 'menu.dashboard-qr',
    path: '/dashboard-qr',
    key: SECTION_KEYS.DASHBOARD_QR,
    access: SECTION_KEYS.DASHBOARD_QR,
    order: 3,
  },*/

  /*[PAGE_NAMES.DASHBOARD_DISEL]: {
    icon: 'AreaChart',
    label: 'menu.DashboardDisel',
    path: '/dashboard-disel',
    key: SECTION_KEYS.DASHBOARD_DISEL,
    access: SECTION_KEYS.DASHBOARD_DISEL,
    order: 4,
  },*/

  [PAGE_NAMES.SOCIAL_LISTENING]: {
    icon: 'AreaChart',
    label: 'menu.Social_Listening',
    path: '/social-listening',
    key: SECTION_KEYS.SOCIAL_LISTENING,
    access: SECTION_KEYS.SOCIAL_LISTENING,
    order: 5,
  },

  [PAGE_NAMES.SURVEY_STATS]: {
    icon: icons.statistics,
    label: 'menu.Survey_Stats',
    path: '/survey-stats',
    key: SECTION_KEYS.SURVEY_STATS,
    access: SECTION_KEYS.SURVEY_STATS,
    order: 6,
  },

  [PAGE_NAMES.ALERTS_STATS]: {
    icon: 'Exception',
    label: 'menu.Alerts_Stats',
    path: '/alerts/stats',
    key: `${SECTION_KEYS.ALERTS}/stats`,
    access: SECTION_KEYS.ALERTS,
    action: 'stats',
    order: 7,
  },

  [PAGE_NAMES.ALERTS_LIST]: {
    icon: 'Exception',
    label: 'menu.Alerts_List',
    path: '/alerts/list',
    key: SECTION_KEYS.ALERTS,
    access: SECTION_KEYS.ALERTS,
    order: 8,
  },

  [PAGE_NAMES.OMOIKANE_AI]: {
    icon: icons.iaMotor,
    label: 'Omoikane AI',
    path: '/omoikane-ai',
    key: SECTION_KEYS.OMOIKANE_AI,
    access: SECTION_KEYS.OMOIKANE_AI,
    order: 9,
  },

  [PAGE_NAMES.POWER_INTELLIGENCE]: {
    icon: 'AreaChart',
    label: 'Power Intelligence',
    path: '/power-intelligence',
    key: SECTION_KEYS.POWER_INTELLIGENCE,
    access: SECTION_KEYS.POWER_INTELLIGENCE,
    order: 10,
  },

  [PAGE_NAMES.ACTION_PLANS]: {
    icon: icons.actionPlans,
    label: 'menu.Action_Plans',
    path: '/action-plans',
    key: SECTION_KEYS.ACTION_PLANS,
    access: SECTION_KEYS.ACTION_PLANS,
    order: 11,
  },

  [PAGE_NAMES.REPORTS]: {
    icon: icons.reports,
    label: 'menu.Reports',
    path: '/reports',
    key: SECTION_KEYS.REPORTS,
    access: SECTION_KEYS.REPORTS,
    order: 12,
  },

  // [PAGE_NAMES.DASHBOARDAI]: {
  //   icon: 'Experiment',
  //   label: 'menu.DashboardAI',
  //   path: '/engine-ai',
  //   key: SECTION_KEYS.DASHBOARDAI,
  //   access: SECTION_KEYS.DASHBOARDAI,
  // },

  // [PAGE_NAMES.DASHBOARDNEW]: {
  //   icon: 'Experiment',
  //   label: 'New Features',
  //   path: '/new-features',
  //   key: SECTION_KEYS.DASHBOARDNEW,
  //   access: SECTION_KEYS.DASHBOARDNEW,
  // },

  // [PAGE_NAMES.EMPLOYEE_EXP]: {
  //   icon: 'Comment',
  //   label: 'Experiencia del Empleado',
  //   path: '/employee-exp',
  //   key: SECTION_KEYS.EMPLOYEE_EXP,
  //   access: SECTION_KEYS.EMPLOYEE_EXP,
  // },

  // [PAGE_NAMES.SUPLIER_EXP]: {
  //   icon: 'Shopping',
  //   label: 'Experiencia del Proveedor',
  //   path: '/suplier-exp',
  //   key: SECTION_KEYS.SUPLIER_EXP,
  //   access: SECTION_KEYS.SUPLIER_EXP,
  // },

  // [PAGE_NAMES.CUSTOMER_JOURNEYS]: {
  //   icon: 'InsertRowLeft',
  //   label: 'menu.Customer_Journeys',
  //   path: '/customer-journeys',
  //   key: SECTION_KEYS.CUSTOMER_JOURNEYS,
  //   access: SECTION_KEYS.CUSTOMER_JOURNEYS,
  // },
}

export const getMenuItems = (
  authorities: Array<Authority>,
  pages: Record<string, string[]> // dahsboard, alerts, reports
): Array<PageMenu> => {
  if (authorities.indexOf('ROLE_ROOT') > -1) {
    return [PAGES_CONFIG.COMPANIES]
  }
  if (authorities.indexOf('ROLE_ADMIN') > -1) {
    return [
      PAGES_CONFIG.USERS,
      PAGES_CONFIG.ROLES,
      // PAGES_CONFIG.DASHBOARD_BUILDER,
      // PAGES_CONFIG.SURVEYS,
      PAGES_CONFIG.LOGICAL_DISTRIBUTIONS,
      PAGES_CONFIG.GEOGRAPHIC_DISTRIBUTIONS,
      PAGES_CONFIG.CRITICAL_MOMENTS,
    ]
  }
  if (pages && !isEmpty(pages)) {
    const pageKeys = Object.keys(pages)
    return Object.keys(PAGES_CONFIG)
      .filter((p) => {
        const action = PAGES_CONFIG[p].action
        return (
          pageKeys.indexOf(PAGES_CONFIG[p].access) > -1 &&
          (action === 'main' ||
            !action ||
            (action && pages[PAGES_CONFIG[p].access].indexOf(action) > -1))
        )
      })
      .map((page) => {
        return PAGES_CONFIG[page]
      })
  }
  return []
}
