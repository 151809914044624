
export interface CustomerJourneyItem {
    id: number
    title: string
    nps: number
    csat: number
}

export interface CustomerJourneyStage {
    id: number
    description?: string
    data: string[] | number[] | CustomerJourneyItem[]
}

export interface CustomerJourneyValue {
    order: number
    name: string
    value: string
}

export interface CxRayValue {
    order: number
    name: string
    key: string
    widgetType?: 'pie' | 'gauge'
}

export interface CustomerJourneyConfig {
    key: number
    number: string
    title: string
    items: CustomerJourneyValue[]
}

export interface CustomerJourneyData {
    id: string
    title: string
    name: string
    value: number | undefined
}

export interface CustomerJourneyStageData extends CustomerJourneyConfig {
    data: CustomerJourneyData[]
}

export enum OmoikaneTab {
    CUSTOMER_JORUNEY,
    CX_RAY,
    CX_ROAD_MAP,
    CLUSTERS
}

export interface OmoikaneConfig {
    enabledTabs: OmoikaneTab[]
    indicators: IndicatorConfig[]
    sentiment: {
        indicators: { [cm: string]: string },
        properties: {
            sentiment: string,
            percentage: string,
            sentimentLocal: string,
            percentageLocal: string
        }
    }
    customerJourney: {
        [cm: string]: (data: any) => CustomerJourneyConfig[]
    }
    cxRay: {
        indicators: { [cm: string]: CxRayValue[] }
    },
    clusters: {
        counters: {
            indicator: string
        },
        detail: {
            indicators: IndicatorConfig[]
        }
    }
}