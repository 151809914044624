import { getCmID } from './commonWidgetsSuzuki'
import distribuidorPosventa from './distribuidorPosventa'
import distribuidorVenta from './distribuidorVenta'
export default {
  title: 'Distribuidor Dashboard',
  description: 'Dashboard de Distribuidor',
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets:(filters: FiltersUI) =>  [
    ...distribuidorVenta.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('venta')))
        )
      },
    })),
    ...distribuidorPosventa.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(getCmID('servicio')),
    })),
  ],
} as DasboardConfig
