

export const widgetsGraph: CommonWidget = {


  /**************************************************************************
   *                                WORD-COUNT
  ***************************************************************************/

  wordCountWithLinks: {
    title: 'Nube de Palabras / Frecuencia en comentarios',
    helpText:
      'Presenta en tiempo real aquello que los clientes nos comparten como sus experiencias más recientes.',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'word-count-with-links',
      type: 'graph',
      indicators: [
        {
          indicator: 'WORD_COUNT_WITH_LINKS',
          keyExtract: ['links', 'tags'],
          singleton: true,
          extras: {},
        },
      ],
    },
  },
  wordCount: {
        title: 'FALTA texto',
        helpText: 'FALTA texto',
        helpLink: '--',
        position: [4, 2],
        size: [2, 2],
        config: {
            id: 'word-count',
            type: 'wordCloud',
            indicators: [
                {
                    indicator: 'WORD_COUNT',
                    keyExtract: ['count', 'value', 'group'],
                    singleton: true
                }
            ]
        }
    },
}
