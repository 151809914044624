

export const widgetsComments: CommonWidget = {

  /**************************************************************************
   *                                COMMENTS
  ***************************************************************************/

  lastComments: {
    title: 'Últimos comentarios',
    helpText: 'Presenta en tiempo real aquello que los clientes nos comparten como sus experiencias más recientes.',
    helpLink: '--',
    position: [0, 23],
    size: [4, 2],
    config: {
      id: 'last-five-comments',
      type: 'comments',
      indicators: [
        {
          indicator: 'comments',
          keyExtract: ['comment', 'createdAt', 'criticalMoment', 'count'],
          singleton: true,
          extras: {},
        },
      ],
    },
  },

  
}
