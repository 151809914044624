import { answeredMapper, getCommonWidget, invalidMapper } from '../dashHelper'
import { commonWidgets, getCmID } from './commonWidgetsSuzuki'

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [0, 0],
      size: [1, 2],
      indicator: 'VENTA_SATISFACCION',
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('venta')))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      indicator: 'POSVENTA_NPS',
      position: [1, 0],
      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('venta')))
        )
      },
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 0],
      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('venta')))
        )
      },
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [3, 0],
      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('venta')))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      indicator: 'POSVENTA_SATISFACCION',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(getCmID('root'))
          ? [0, 2]
          : [0, 0]
      },
      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('servicio')))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      indicator: 'POSVENTA_NPS',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(getCmID('root'))
          ? [1, 2]
          : [1, 0]
      },
      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('servicio')))
        )
      },
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(getCmID('root'))
          ? [2, 2]
          : [2, 0]
      },
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('servicio')))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      mapper: answeredMapper,
      indicator: 'postventa-respuestas-gauge',
      url: 'survey-responses/values',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(getCmID('root'))
          ? [3, 2]
          : [3, 0]
      },
      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('servicio')))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label: 'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(getCmID('root'))
          ? [0, 4]
          : [0, 2]
      },
      //position: [0, 4],
      size: [4, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('venta')))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      indicator: 'POSVENTA_CUSTOMER_JOURNEY',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label: 'widgets.indicatorId.' + item.groupId
          }
        })
      },
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(getCmID('root'))
          ? [0, 6]
          : [0, 2]
      },
      //position: [0, 4],
      size: [4, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(getCmID('root')) ||
            filters.criticalMoments.includes(getCmID('servicio')))
        )
      },
    }),
    {
      title: 'Competencias conductuales a desarrollar',
      helpText: 'Competencias conductuales a desarrollar',
      helpLink: '--',
      position: [0, 8],
      size: [4, 2],
      config: {
        id: 'worst-tags-venta',
        type: 'ranking',
        indicators: [
          {
            indicator: 'SENTIMENT',
            keyExtract: ['group', 'positive', 'groupName'],
            label: 'groupName',
            singleton: true,
            grouped: 'tag',
            sort: { positive: 'asc' },
            limit: 3,
            extras: {
              resultType: 'bottom',
            },
          },
        ],
      },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      position: [2, 10],
      size: [4, 2],
      indicator: 'SENTIMENT'
    }),
  ],
} as DasboardConfig
