import { answeredMapper, getCommonWidget, invalidMapper } from "../dashHelper"
import { COLORS_CONFIG_BAR, commonWidgets, getCmID } from "./commonWidgetsSuzuki"

export default {
  title: "Nacional Postventa Dashboard",
  description: "Dashboard de Nacional Postventa",
   backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets:(filters: FiltersUI) =>  [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      position: [0, 4],
      size: [1, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      position: [1, 4],
      size: [1, 2],
      indicator: 'POSVENTA_NPS',
      criticalMomentId: getCmID("servicio"),
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 4],
      size: [1, 2],
      criticalMomentId: getCmID("venta"),
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [3, 4],
      size: [1, 2],
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label: 'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'POSVENTA_CUSTOMER_JOURNEY',
      position: [0, 6],
      size: [4, 2],
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasPostventa',
      position: [0, 8],
      size: [2, 2],
      extraConfigs: { fontSize: '18px' },
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasPostventa',
      position: [2, 8],
      size: [2, 2],
      extraConfigs: { fontSize: '18px' },
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: getCmID("servicio"),
    }),
     getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'widgets.geoLocationMap',
      position: [0, 10],
      size: [2, 2],
      criticalMomentId: getCmID("servicio"),
      indicator: 'POSVENTA_NPS',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [2, 10],
      size: [1, 2],
      extraConfigs: {
        fontSize: '18px',
        //calculatedLimit: (data: any) => Math.min(3, (data.length) / 2),
        limit: 3
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [3, 10],
      size: [1, 2],
      extraConfigs: {
        fontSize: '18px',
        //calculatedLimit: (data: any) => Math.min(3, (data.length) / 2),
        limit: 3
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsPostventa',
      position: [0, 12],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("servicio"),
      extras: {
        resultType: 'top',
        trunc: "HALF_DOWN",
        topSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsPostventa',
      position: [2, 12],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("servicio"),
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 13],
      size: [4, 4],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("servicio"),
    }),
    {
      title: "widgets.zonalSatisfaction",
      helpText: "widgets.zonalSatisfaction",
      helpLink: "--",
      // [Column, Row]
      position: [0, 17],
      size: [4, 2],
      config: {
        id: "satisfaction-zona",
        type: "bar",
        fillMode: "",
        //colors: '#33b9ce',
        colors: COLORS_CONFIG_BAR,
        showValues: true,
        percentageScale: false,
        indicators: [
          {
            indicator: "POSVENTA_SATISFACCION",
            grouped: "geoLocation",
            keyExtract: ["group", "value"],
            label: "group",
            value: "value",
            singleton: true,
            extras: {
              groupByLevel: 2,
              criticalMomentId: getCmID("servicio"),
            },
          },
        ],
      },
    },
    {
      title: "widgets.distribuidorSatisfaction",
      helpText: "widgets.distribuidorSatisfaction",
      helpLink: "--",
      // [Column, Row]
      position: [0, 19],
      size: [4, 2],
      config: {
        id: "satisfaccion-dist",
        type: "bar",
        fillMode: "",
        //colors: '#33b9ce',
        colors: COLORS_CONFIG_BAR,
        showValues: true,
        percentageScale: false,
        indicators: [
          {
            indicator: "POSVENTA_SATISFACCION",
            grouped: "geoLocation",
            keyExtract: ["group", "value"],
            label: "group",
            value: "value",
            singleton: true,
            extras: {
              groupByLevel: 3,
              criticalMomentId: getCmID("servicio"),
            },
          },
        ],
      },
    },
    {
      title: "widgets.otherIndicators",
      type: "label",
      position: [0, 21],
      size: [4, 1],
    } /*,
    {
      title: 'widgets.appointmentPostventa',
      helpText: 'widgets.appointmentPostventa',
      helpLink: '--',
      // [Column, Row]
      position: [0, 19],
      size: [2, 2],
      config: {
        id: 'posventa-puntualidad',
        type: 'pie',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        fillMode: '',
        showValues: true,
        //percentageScale: false,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'POSVENTA_AGENDAR_CITA',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: getCmID('servicio'),
            },
          },
        ],
      },
    },
    {
      title: 'widgets.waitingRoomPostventa',
      helpText: 'widgets.waitingRoomPostventa',
      helpLink: '--',
      // [Column, Row]
      position: [2, 19],
      size: [1, 2],
      config: {
        id: 'posventa-instalaciones',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_INSTALACIONES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: getCmID('servicio'),
            },
          },
        ],
      },
    },
    {
      title: 'widgets.signagePostventa',
      helpText: 'widgets.signagePostventa',
      helpLink: '--',
      // [Column, Row]
      position: [3, 19],
      size: [1, 2],
      config: {
        id: 'posventa-senaletica',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'POSVENTA_SENIALECTICA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: getCmID('servicio'),
            },
          },
        ],
      },
    }*/,
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.paymentPostventa',
      position: [0, 22],
      size: [1, 2],
      indicator: 'POSVENTA_FACTURACION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.budgetPostventa',
      position: [1, 22],
      size: [1, 2],
      indicator: 'POSVENTA_PRESUPUESTO',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.repairPostventa',
      position: [2, 22],
      size: [1, 2],
      indicator: 'POSVENTA_REPARACION_VEHICULO',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.repairAvailablePostventa',
      position: [3, 22],
      size: [1, 2],
      indicator: 'POSVENTA_DISPONIBILIDAD_TALLER',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.explicacionTrabajoPostventa',
      position: [0, 24],
      size: [1, 2],
      indicator: 'POSVENTA_EXPLICACION_TRABAJO',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByQuestion',
      title: 'Peso de atributos sobre Satisfacción',
      position: [1, 24],
      size: [3, 2],
      indicator: 'ATRIBUTOS_SATISFACCION_POSTVENTA',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByMonthRegression',
      title: 'Proyección - Satisfacción',
      position: [0, 26],
      size: [4, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
      mapper: (data: any) => {
        return data &&
          data[0] &&
          data[0].source &&
          data[0].source.map((item: number, idx: any) => ({
            group: item['groupName'],
            label: item['groupName'],
            value: data[0].source[idx].value * 10,
          }))
      },
      extras: {
        aggregation: 'linearRegression'
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 28],
      size: [4, 2],
      criticalMomentId: getCmID("servicio"),
    }),
  ],
} as DasboardConfig
