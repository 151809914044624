import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/action-plans',
  layout: true,
  getComponent: async () => {
    const ActionPlansLayout = await import(
      /* webpackChunkName: "action-plans-modules" */ './components/ActionPlansLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "action-plans-modules" */ './ActionPlansModule'
    )

    injectReducer(store, { key: 'actionPlans', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: ActionPlansLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/:id/detail',
      title: 'action_plans.detail', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ActionPlansDetailView = await import(
          /* webpackChunkName: "action-plans-modules"*/ './components/ActionPlansDetailView'
        )
        return {
          children: ActionPlansDetailView.default,
        }
      },
    },
    {
      path: '/new',
      title: 'action_plans.new', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ActionPlanFormView = await import(
          /* webpackChunkName: "action-plans-modules"*/ './components/ActionPlanFormView'
        )
        return {
          children: ActionPlanFormView.default,
        }
      },
    },
    {
      path: '/:id?/',
      title: 'titles.Action_Plans', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ActionPlansStatsView = await import(
          /* webpackChunkName: "action-plans-modules"*/ './components/ActionPlansStatsView'
        )
        return {
          children: ActionPlansStatsView.default,
        }
      },
    },
  ],
})

