import { getCommonWidget, renderForCriticalMoment } from '../dashHelper'
import { commonWidgets } from './commonWidgetsOxxo'
import defaultWidgets from './default'

export default {
  title: 'Demo Dashboard',
  description: 'Dashboard de perfil Demo',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    ...defaultWidgets.widgets(filters),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'NPS por Estación',
      position: [0, 27],
      size: [4, 2],
      indicator: 'willingness-to-recommend',
      extraConfigs: {
        wrapTexts: true
      },
      render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsLineTrendByMonthAndGeo',
      title: 'Tendencia % EESS',
      position: [0, 29],
      size: [2, 2],
      indicator: 'survey-count',
      render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsLineTrendByGeo',
      title: '% EESS por Región',
      position: [2, 29],
      size: [2, 2],
      indicator: 'survey-count',
      render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
  ],
} as DasboardConfig
