import { answeredMapper, getCommonWidget, invalidMapper } from '../dashHelper'
import {
  COLORS_CONFIG,
  COLORS_CONFIG_BAR,
  commonWidgets,
} from './commonWidgetsMitsu'

export default {
  title: 'Nacional Venta Dashboard',
  description: 'Dashboard de Nacional Venta',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1]
    }),
    //1
    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'survey-response-counters',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'survey-response-counters',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      position: [2, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      position: [3, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      position: [0, 6],
      size: [1, 2],
      indicator: 'VENTA_NPS',
      criticalMomentId: 1,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [1, 6],
      size: [1, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 6],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      position: [3, 6],
      size: [1, 2],
      mapper: answeredMapper,
      url: 'survey-responses/values',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      position: [0, 8],
      size: [4, 2],
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasVenta',
      position: [0, 10],
      size: [2, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasVenta',
      position: [2, 10],
      size: [2, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1
    }),
    //3
    {
      title: 'Estaciones',
      type: 'label',
      position: [0, 12],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de oficinas',
      position: [0, 13],
      size: [2, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsVenta',
      position: [2, 13],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsVenta',
      position: [3, 13],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsVenta',
      position: [0, 15],
      size: [1, 2],
      indicator: 'sentiment',
      criticalMomentId: 1,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsVenta',
      position: [1, 15],
      size: [1, 2],
      indicator: 'sentiment',
      criticalMomentId: 1,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [2, 15],
      size: [2, 2],
      indicator: 'WORD_COUNT_WITH_LINKS',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 17],
      size: [4, 4],
      criticalMomentId: 1,
    }),
    {
      title: 'widgets.zonalSatisfaction',
      helpText: 'widgets.zonalSatisfaction',
      // [Column, Row]
      position: [0, 21],
      size: [4, 2],
      config: {
        id: 'venta-satisfaction-zona',
        type: 'bar',
        fillMode: '',
        //colors: '#33b9ce',
        colors: COLORS_CONFIG_BAR,
        showValues: true,
        percentageScale: false,
        indicators: [
          {
            indicator: 'VENTA_SATISFACCION',
            grouped: 'geoLocation',
            keyExtract: ['group', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupByLevel: 2,
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.distribuidorSatisfaction',
      helpText: 'widgets.distribuidorSatisfaction',
      // [Column, Row]
      position: [0, 23],
      size: [4, 2],
      config: {
        id: 'venta-satisfaccion-dist',
        type: 'bar',
        fillMode: '',
        //colors: '#33b9ce',
        colors: COLORS_CONFIG_BAR,
        percentageScale: false,
        showValues: true,
        //percentageScale: false,
        indicators: [
          {
            indicator: 'VENTA_SATISFACCION',
            grouped: 'geoLocation',
            keyExtract: ['group', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupByLevel: 3,
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.driveTestReasonsVenta',
      helpText: 'widgets.driveTestReasonsVenta',
      // [Column, Row]
      position: [0, 25],
      size: [2, 2],
      config: {
        id: 'venta-no-prueba',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        //percentageScale: false,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'VENTA_SIN_PRUEBA_MANEJO_MOTIVO',
            //grouped: 'responseValue',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 1,
              groupNameFormat: 'description',
            },
          },
        ],
      },
    },
    {
      title: 'widgets.financingReasonsVenta',
      helpText: 'widgets.financingReasonsVenta',
      // [Column, Row]
      position: [2, 25],
      size: [2, 2],
      config: {
        id: 'venta-no-financiacion',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        //percentageScale: false,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'VENTA_SIN_FINANCIAMIENTO_MOTIVO',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      position: [0, 27],
      size: [4, 3],
      indicator: 'sentiment',
      criticalMomentId: 1,
    }),
    {
      title: 'widgets.otherIndicators',
      type: 'label',
      position: [0, 30],
      size: [4, 1],
    },
    {
      title: 'widgets.instalationsVenta',
      helpText: 'widgets.instalationsVenta',
      // [Column, Row]
      position: [0, 31],
      size: [1, 2],
      config: {
        id: 'venta-instalaciones',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'VENTA_INSTALACIONES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.parkingVenta',
      helpText: 'widgets.parkingVenta',
      // [Column, Row]
      position: [1, 31],
      size: [1, 2],
      config: {
        id: 'venta-estacionamiento',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'VENTA_ESTACIONAMIENTO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.upsellingVenta',
      helpText: 'widgets.upsellingVenta',
      // [Column, Row]
      position: [2, 31],
      size: [1, 2],
      config: {
        id: 'venta-accesorios-adicionales',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'VENTA_ACCESORIOS_ADICIONALES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.carStatusVenta',
      helpText: 'widgets.carStatusVenta',
      // [Column, Row]
      position: [3, 31],
      size: [1, 2],
      config: {
        id: 'venta-estado-vehiculo',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'VENTA_ESTADO_VEHICULO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.warrantyExplanation',
      helpText: 'widgets.warrantyExplanation',
      // [Column, Row]
      position: [0, 33],
      size: [1, 2],
      config: {
        id: 'venta-garantia',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'VENTA_GARANTIA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
  ],
} as DasboardConfig
