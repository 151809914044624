import _, { isNumber } from "lodash"
import Immutable from 'seamless-immutable'

export const answeredMapper = (data: any): any => {
  let answered: any = data !== undefined && data.find((item: any) => item['group'] == 'ANSWERED')
  answered = answered && answered['count'] !== undefined ? answered['count'] : 0

  let inProgress: any = data !== undefined && data.find((item: any) => item['group'] == 'IN_PROGRESS')
  inProgress =
    inProgress && inProgress['count'] !== undefined ? inProgress['count'] : 0
  const total = answered + inProgress
  //return inProgress > 0 ? { value: (answered / inProgress) * 100 } : []
  return total > 0 ? { value: (answered / total) * 100 } : []
}

export const invalidMapper = (data: any): any => {
  const total = data !== undefined && data
    .map((item: any) => item['count'])
    .reduce((prev: any, curr: any) => prev + curr, 0)

  let invalid: any = data !== undefined
    && data.find((item: any) => item['group'] == 'INVALID')

  invalid = invalid && invalid['count'] !== undefined ? invalid['count'] : 0
  //return { value: total > 0 ? ((total - invalid) / total) * 100 : 0 }
  return total > 0 ? { value: ((total - invalid) / total) * 100 } : []
}

export const yesNoMapper = (data: any) => {
  let yes: any = data.find((item: any) => item['group'] == 'SI')
  yes = yes && yes['count'] !== undefined ? yes['count'] : 0

  let no: any = data.find((item: any) => item['group'] == 'NO')
  no = no && no['count'] !== undefined ? no['count'] : 0
  return { value: no > 0 ? (yes / no) * 100 : 100 }
}

export const inversePorcMapper = (data: any) => {
  return { value: 100 - data['value'] }
}

export const groupMapper = (data: any) => {
  return data.map((item: any) => ({
    id: item['group'] == null ? 'Indefinido' : item['group'],
    label: item['group'] == null ? 'Indefinido' : item['group'],
    value: item['value'],
    count: item.count
  }))
}
export const dummyMapper = (data: any) => {
    return data
}

export const groupMapperLateral = (data: any) => {
  return data.map((item: any) => ({
    id: item['group'] == null ? 'Indefinido' : item['group'],
    label:
      item['group'] == null
        ? 'Indefinido'
        : item['group'].substr(0, 35).trim() + '.',
    value: item['value'],
    count: item.count
  }))
}

export const pieColors = [
  '#f5b49f',
  '#a3d1d6',
  '#a2d1a2',
  '#b29bc2',
  '#f3b6d8',
  '#fff9b1',
  '#3f3166',
  '#c878e3',
  '#baaee5'
]


export const grayColors = [
  '#D5DBDB',
  '#E8E8E8',
  '#D8D8D8',
  '#D3D3D3',
  '#C0C0C0',
  '#B0B0B0',
  '#A0A0A0',
  '#888888',
  '#686868',
  '#606060'
]

export const getMitsubishiFilterCriticalMoments = (
  filtersData: Record<string, any>,
  userProfiles: UserProfile[]
): CriticalMoment[] => {

  const profile = userProfiles
    && userProfiles
    && userProfiles[0]
    && userProfiles[0].name
    ? userProfiles[0].name.toLowerCase() : undefined

  let criticalMoments = undefined
  if (profile && ['cxo', 'distribuidor', 'demo'].includes(profile)) {
    criticalMoments = filtersData.criticalMoments
      && filtersData.criticalMoments[0]
      && filtersData.criticalMoments[0].children
      ? filtersData.criticalMoments[0].children
      : filtersData.criticalMoments

    if (['cxo', 'demo'].includes(profile)) {
      const general = {
        id: 0,
        name: 'General',
        description: 'General'
      }
      criticalMoments = [
        general,
        ...criticalMoments,
      ]
    }
  }

  return criticalMoments
}

export const getFilterCriticalMoments = (filtersData: Record<string, any>): CriticalMoment[] => {
  const criticalMoments = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].children
    ? filtersData.criticalMoments[0].children
    : filtersData.criticalMoments

  const generalId = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].id
    ? filtersData.criticalMoments[0].id
    : 0

  const general = {
    id: generalId,
    name: 'Todos',
    description: 'Todos'
  }

  const cms = criticalMoments.filter((cm: any) => cm.name !== 'TODOS')

  return [
    general,
    ...cms,
  ]
}

export const getFilterCriticalMomentDefault = (filtersData: Record<string, any>): number => {
  const criticalMoments = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].id
    ? filtersData.criticalMoments[0].id
    : 0

  return criticalMoments
}

export const getMitsubishiFilterCriticalMomentDefault = (filtersData: Record<string, any>, userProfiles: UserProfile[]): number => {
  const profile = userProfiles
    && userProfiles
    && userProfiles[0]
    && userProfiles[0].name
    ? userProfiles[0].name.toLowerCase() : undefined

  return profile && ['cxo', 'demo'].includes(profile) ? 0 : 1
}

export const getCommonWidget = (props: {
  commonWidgets: CommonWidget,
  name: string,
  position: number[] | ((filters: FiltersUI) => number[])
  size: number[]
  criticalMomentId?: number
  criticalMomentInclude?: number[]
  geoLocationId?: number
  geoLocationInclude?: number[]
  key?: string,
  indicator?: string,
  url?: string,
  title?: string,
  subTitle?: string
  showSample?: boolean
  render?: (filters: FiltersUI) => boolean
  mapper?: (data: Object[], extra?: any) => BaseEntity[]
  extraIndicatorConfigs?: { [key: string]: any },
  extraConfigs?: { [key: string]: any },
  extras?: { [key: string]: any }
}) => {
  const {
    name,
    commonWidgets,
    title,
    subTitle,
    position,
    size,
    render,
    showSample,
    key,
    indicator,
    mapper,
    extraConfigs,
    extraIndicatorConfigs,
    url,
    extras,
    criticalMomentId,
    geoLocationId
  } = props

  //console.log('Obteniendo widget ' + name)
  const widget = commonWidgets[name]

  // Critical Moment
  var criticalMomentInclude = props.criticalMomentInclude
    ? props.criticalMomentInclude
    : undefined

  if (criticalMomentId !== undefined) {
    criticalMomentInclude = criticalMomentInclude !== undefined
      ? criticalMomentInclude.concat([criticalMomentId])
      : [criticalMomentId]
  }

  // GeoLocation
  var geoLocationInclude = props.geoLocationInclude
    ? props.geoLocationInclude
    : undefined

  if (geoLocationId !== undefined) {
    geoLocationInclude = geoLocationInclude !== undefined
      ? geoLocationInclude.concat([geoLocationId])
      : [geoLocationId]
  }

  const widgetId = widget?.config?.id + '-'
    + widget.config?.type + '-'
    + indicator + '-'
    + (key !== undefined ? key + '-' : '')
    + (url !== undefined ? (url + '-') : '')
    + (criticalMomentInclude !== undefined && criticalMomentInclude?.length > 0
      ? criticalMomentInclude?.join('-') : '')
    + (geoLocationInclude !== undefined && geoLocationInclude?.length > 0
      ? geoLocationInclude?.join('-') : '')

  return {
    ...widget,
    title: title || widget.title,
    subTitle: subTitle || widget.subTitle,
    position: position,
    size: size,
    widgetName: name,
    render: render ?? widget.render,
    config: {
      ...widget.config,
      type: widget.config?.type ?? 'Falta definir tipo de widget ' + widgetId,
      id: widgetId,
      showSample: showSample ?? widget.config?.showSample,
      mapper: mapper ?? widget.config?.mapper,
      ...extraConfigs,
      indicators: widget.config?.indicators?.map((ind: any, idx: number) => {
        return {
          ...ind,
          key: key || ind.key,
          indicator: indicator || ind.indicator,
          url: url || ind.url,
          ...extraIndicatorConfigs,
          extras: {
            ...ind.extras,
            ...extras,
            criticalMomentInclude: criticalMomentInclude,
            geographicDistributions: geoLocationInclude
          }
        }
      })
    }
  }
}

export const renderForCriticalMoment = (filters: FiltersUI, cmIncludes: number[]) => {
  return (
    filters !== undefined &&
    filters.criticalMoments !== undefined &&
    filters.criticalMoments !== null &&
    filters.criticalMoments.some(cm => cmIncludes.includes(cm))
  )
}

export const renderForGeoLocation = (filters: FiltersUI, geoIncludes: number[]) => {
  return (
    filters !== undefined &&
    filters.geographicDistributions !== undefined &&
    filters.geographicDistributions !== null &&
    filters.geographicDistributions.some(geo => geoIncludes.includes(geo))
  )
}

const getWidgetTypeConfig = (wdg: Widget): WidgetConfig => {
  const defaultIndicators = wdg.config?.indicators?.map(ind => ({
    ...ind,
    indicator: ind.indicator ?? ind.key,
    //grouped: 'formattedMonth',
    keyExtract: ['passives', 'detractors', 'promoters',
      'passiveCount', 'detractorCount', 'promoterCount',
      'value', 'groupName', 'count'],
    label: 'group',
    value: 'value',
    singleton: false,
    historic: false,
    extras: {
      ...ind.extras,
      //periods: 6
    },
  }))

  const defaultConfigs = {
    ...wdg.config,
    id: wdg.config?.id ?? 'default-id-' + wdg.title,
    showHistoric: false,
    type: 'trend',
    indicators: defaultIndicators
  }

  if (wdg.config?.type === 'nps') {
    return {
      ...defaultConfigs,
      type: 'semicircle'
    }
  }

  return defaultConfigs
}

export const configBackDashboards = (props: {
  backDashboards?: Record<string, DasboardConfig>,
  staticDashboards?: Record<string, DasboardConfig>,
  filters: FiltersUI,
}): Widget[] => {

  const { filters, backDashboards, staticDashboards } = props

  let newBacks = {}
  backDashboards !== undefined
    && Object.keys(backDashboards).forEach((key: string) => {
      const dash = backDashboards[key]
      newBacks[key] = {
        ...dash,
        dashType: 'back'
      }
    })

  let newStatics = {}
  staticDashboards !== undefined
    && Object.keys(staticDashboards).forEach((key: string) => {
      const dash = staticDashboards[key]
      newStatics[key] = {
        ...dash,
        dashType: 'static'
      }
    })

  const allDashboards = {
    ...newBacks,
    ...newStatics
  }

  if (allDashboards === undefined || allDashboards === null || Object.keys(allDashboards).length === 0) {
    return []
  }

  return Object.keys(allDashboards)
    .filter(key => allDashboards[key].widgets.length > 0)
    .map((key: string) => {
      const dash = allDashboards[key]
      const criticalMomentId = Number(key)
      let widgets: Widget[] = []

      //console.log('Config all dashboards para ')
      //console.log(dash)

      if (isNumber(criticalMomentId)) {
        const dashWidgets = dash.dashType === 'back'
          ? dash.widgets
          : dash.widgets(filters)

        widgets = [
          ...dashWidgets
            .map((wdg: any) => {
              const widgetConfig = dash.dashType === 'back'
                ? getWidgetTypeConfig(wdg)
                : wdg.config
              return {
                ...wdg,
                config: widgetConfig,
                render: (filters: FiltersUI) => renderForCriticalMoment(filters, [criticalMomentId]),
              }
            }),
        ]
      }

      return widgets
    })
    .filter((item: any) => (item !== undefined || _.isEmpty(item)))
    .flat(1)
}