import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

export const omoikaneIamsa: OmoikaneConfig = {
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP
    ],
    indicators: [
        {
            indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
            key: 'customer-journey',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'SATISFACCION_GENERAL',
            key: 'satisfaction-general',
            keyExtract: [],
            grouped: 'geoLocation',
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'EXPERIENCIA_MARCA',
            key: 'experiencia-marca',
            keyExtract: [],
            grouped: 'logicalLocation',
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'sentiment',
            key: 'sentiment-general',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true
            }
        },
    ],
    sentiment: {
        indicators: {
            default: 'sentiment-general'
        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'percentageLocal',
            percentageLocal: 'sentimentLocal'
        }
    },
    cxRay: {
        indicators: {
            default: [
                { order: 1, name: 'CSAT', key: 'satisfaction-general', widgetType: 'pie' },
                { order: 2, name: 'EM', key: 'experiencia-marca', widgetType: 'gauge' },
            ]
        }
    },
    clusters: {
        counters: { indicator: '' },
        detail: { indicators: [] }
    },
    customerJourney: {
        default: (data: any) => {
            return data['customer-journey']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group.replaceAll('-', ' '),
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
        }
    }
}
