import RestApiBuilder from '@utils/RestApiBuilder'
import { ApisauceInstance } from 'apisauce'
import parseFilters from '@utils/parseFilters'

/**
 * Expose all endponts for 'reports'.
 */
export default <T>(api: ApisauceInstance): ApiObject => ({
  ...RestApiBuilder<T>('reports', api),
  download: (reportName: string, filters: any, type) => {
    return api.get(`reports/${reportName}`, parseFilters(filters), {
      responseType: 'blob',
      headers: {
        accept: type,
        'content-type': type,
      },
    })
  },
})

// {
//   // criticalmoment: filters.criticalMoments,
//   // geographic: filters.geographicDistributions,
//   // logical: filters.logicalDistributions,
//   start: dayjs(filters.dateFrom).startOf('day').add(2, 'hours').valueOf(),
//   end: dayjs(filters.dateTo).endOf('day').valueOf(),
//   // userid: filters.userid,
//   // cluster: filters.cluster,
// }
