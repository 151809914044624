import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

export const omoikaneMitsubishi: OmoikaneConfig = {
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP
    ],
    indicators: [
        {
            indicator: 'VENTA_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'POSVENTA_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'REPARACION_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'sentiment',
            key: 'sentiment-venta',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'sentiment',
            key: 'sentiment-postventa',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [2]
            }
        },
        {
            indicator: 'sentiment',
            key: 'sentiment-reparacion',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [3]
            }
        },
        {
            indicator: 'VENTA_SATISFACCION',
            key: 'satisfaction-venta',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'POSVENTA_SATISFACCION',
            key: 'satisfaction-postventa',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [2]
            }
        },
        {
            indicator: 'REPARACION_SATISFACCION',
            key: 'satisfaction-reparacion',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [3]
            }
        },
        {
            indicator: 'VENTA_NPS',
            key: 'nps-venta',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'POSVENTA_NPS',
            key: 'nps-postventa',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [2]
            }
        },
        {
            indicator: 'REPARACION_NPS',
            key: 'nps-reparacion',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [3]
            }
        },
        /*{
            indicator: 'survey-count',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
            }
        },*/
    ],
    sentiment: {
        indicators: {
            VENTA: 'sentiment-venta',
            POSVENTA: 'sentiment-postventa',
            REPARACION: 'sentiment-reparacion'
        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'percentageLocal',
            percentageLocal: 'sentimentLocal'
        }
    },
    cxRay: {
        indicators: {
            VENTA: [
                { order: 1, name: 'NPS', key: 'nps-venta', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-venta', widgetType: 'gauge' },
            ],
            POSVENTA: [
                { order: 1, name: 'NPS', key: 'nps-postventa', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-postventa', widgetType: 'gauge' },
            ],
            REPARACION: [
                { order: 1, name: 'NPS', key: 'nps-reparacion', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-reparacion', widgetType: 'gauge' },
            ],
        }
    },
    clusters: {
        counters: { indicator: '' },
        detail: { indicators: [] }
    },
    customerJourney: {
        VENTA: (data: any) => {
            return data['VENTA_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
        },
        POSVENTA: (data: any) => {
            return data['POSVENTA_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
        },
        REPARACION: (data: any) => {
            return data['REPARACION_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
        }
    }
}
