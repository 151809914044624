import parseFilters from '@utils/parseFilters'
import { ApisauceInstance } from 'apisauce'
import _ from 'lodash'

/**
 * Expose all endponts for 'Alerts'.
 */
export default <T>(api: ApisauceInstance): ApiObject => ({
  //...RestApiBuilder<T>('action-plans', api),
  detail: (geoLocationId) =>
    api.get('action-plans', { geoLocationId: geoLocationId, }),
  dashboard: (filters: FiltersUI) =>
    api.get('action-plans/dashboard', {
      ..._.pick(parseFilters(filters), ['geoLocationInclude',]),
    }),
  templates: (indicatorId: number) =>
    api.get('action-plan-templates', { indicatorId, }),
  create: (data: ActionPlan) => api.post('action-plans', data),
  updateActivityProgress: (
    actionPlanId: number,
    activityId: number,
    progress: number
  ) =>
    api.put(`/action-plans/${actionPlanId}/activities/${activityId}/progress`, {
      progress: progress,
    }),
})
