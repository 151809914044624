import { widgetsBars } from './widgetsBars'
import { widgetsComments } from './widgetsComments'
import { widgetsGauge } from './widgetsGauge'
import { widgetsGraph } from './widgetsGraph'
import { widgetsIshikawa } from './widgetsIshikawa'
import { widgetsLine } from './widgetsLine'
import { widgetsMap } from './widgetsMap'
import { widgetsMultipleTrend } from './widgetsMultipleTrend'
import { widgetsPies } from './widgetsPies'
import { widgetsRanking } from './widgetsRanking'
import { widgetsSentimentAnalysis } from './widgetsSentimentAnalysis'
import { widgetsTrend } from './widgetsTrend'

export const COLORS_CONFIG = [
  {
    color: '#F42313',
    condition: '<=' as const,
    key: 'value',
    value: 3,
  },
  {
    color: '#FAEF6B',
    condition: '>' as const,
    key: 'value',
    value: 3,
  },
  {
    color: '#6DE3B4',
    condition: '>' as const,
    key: 'value',
    value: 4,
  },
]

export const COLORS_CONFIG_BAR = [
  {
    color: '#F42313',
    condition: '<=' as const,
    key: 'value',
    value: 6,
  },
  {
    color: '#FAEF6B',
    condition: '>' as const,
    key: 'value',
    value: 6,
  },
  {
    color: '#6DE3B4',
    condition: '>' as const,
    key: 'value',
    value: 8,
  },
]

export const widgetsLibrary: CommonWidget = {
  ...widgetsBars,
  ...widgetsComments,
  ...widgetsGraph,
  ...widgetsGauge,
  ...widgetsIshikawa,
  ...widgetsLine,
  ...widgetsMap,
  ...widgetsMultipleTrend,
  ...widgetsPies,
  ...widgetsRanking,
  ...widgetsSentimentAnalysis,
  ...widgetsTrend,
}
