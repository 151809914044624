import { getCommonWidget, invalidMapper, answeredMapper } from "../dashHelper";
import { commonWidgets } from "./commonWidgetsCaffenio";


export default {
  title: 'Lanzamiento Dashboard',
  description: 'Lanzamiento Default',
  widgets:(filters: FiltersUI) =>  [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: "Satisfacción General",
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'nuevos_lanzamientos_pregunta_2',
      criticalMomentId: 10251
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Satisfacción General',
      position: [0, 3],
      size: [1, 2],
      indicator: 'nuevos_lanzamientos_pregunta_2',
      criticalMomentId: 10251
    }),
     getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Recomedación',
      position: [1, 3],
      size: [1, 2],
      indicator: 'nuevos_lanzamientos_pregunta_6',
      criticalMomentId: 10251,
      extras: {
        periods: 6
      }
    }),
   	getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Recompra',
      position: [2, 3],
      size: [1, 2],
      indicator: 'nuevos_lanzamientos_pregunta_5',
      criticalMomentId: 10251,
      extras: {
        periods: 6
      }
    }),
      getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: '% Registros válidos para envío de encuestas',
      position: [3, 3],
      size: [1, 2],
      criticalMomentId: 10251,
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
     getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: '% tasa de respuesta',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [0, 5],
      size: [1, 2],
      criticalMomentId: 10251,
    }),
	 getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa puntos de ventas',
      position: [1, 5],
      size: [2, 2],
      criticalMomentId: 10251,
      indicator: 'nuevos_lanzamientos_nps',
    }),
    
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Analisis de Sentimientos',
      position: [0, 7],
      size: [4, 4],
      indicator: "SENTIMENT",
      criticalMomentId: 10251
    }),
    {
      title: "Verbalización",
      helpText: "Presenta en tiempo real aquello que los clientes nos comparten como sus experiencias más recientes.",
      helpLink: "--",
      position: [0, 11],
      size: [4, 2],
      config: {
        id: "word-count",
        type: "wordCloud",
        indicators: [
          {
            indicator: "WORD_COUNT",
            keyExtract: ["count", "value", "group"],
            singleton: true
          }
        ]
      },
      criticalMomentId: 10251
    },
     getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 15],
      size: [4, 2],
      criticalMomentId: 10251,
    }),
  ]
}
