import { getClientConfigs, } from './clientConfigs'

import logoClientship from '@assets/images/logo-clientship.png'
import { OmoikaneConfig } from './omoikane/omoikaneCommons'
import { ClientDashboardConfig, DASHBOARDS, DashboardConfigInfo, DashboardList } from './dashboard/dashboardCommons'
import { FILTER_TYPES, METADADATA_LOAD } from './configs/configCommons'

interface ClientConfig {
  // General
  getClientName: () => string
  getVocBackendUrl: () => string
  getLcmBackendUrl: () => string
  getClientLogo: () => any
  getClientLogoSmall: () => any
  getClientshipLogo: () => any
  getPublicUrl: () => string | undefined
  supportsInternationalization: () => boolean

  // Filters
  includesFilterDate: () => boolean
  includesFilterGeo: () => boolean
  includesFilterLogical: () => boolean
  includesFilterCriticalMoment: () => boolean
  includesFilterCluster: () => boolean
  useCriticalMoments: () => boolean
  useLogicalLocations: () => boolean
  useGeoLocations: () => boolean
  useClusters: () => boolean
  useWorkflows: () => boolean
  getGeosRootId: () => number
  getLogicalsRootId: () => number
  getFilterFromDateSubstract: () => number
  getFilterToDateSubstract: () => number
  getCriticalMomentsRootId: () => number
  getCriticalMomentFilterType: () => 'tree' | 'select'
  getFilterCriticalMoments: (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => CriticalMoment[]
  getFilterCriticalMomentDefault: (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => number

  // Dashboard
  getClientMenuDashboards: () => DASHBOARDS[]
  getDashboards: () => ClientDashboardConfig
  useBackDashboards: () => boolean
  getStaticDashboards: () => DashboardList
  getDashboardConfig: (dashboard: string) => DashboardConfigInfo
  getSentimentCommentsIndicatorName: () => string

  // Reports
  useDynamicReports: () => boolean
  getMsExcelReportExt: () => string

  // Omoikane
  getOmoikaneConfig: () => OmoikaneConfig

  // Survey Responses
  getSurveyResponsesUrl: () => string
}

export default function useClientConfig(): ClientConfig {
  const getClientName = (): string => {
    const nodeEnvironment: string | undefined = process.env.NODE_ENV
    //console.log('CLIENTE: ' + nodeEnvironment)

    if (nodeEnvironment === undefined) {
      throw new Error('Cliente no definido!!!')
    }

    return nodeEnvironment
  }

  const getVocBackendUrl = (): string => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.vocBackendUrl
  }

  const getLcmBackendUrl = (): string => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.lcmBackendUrl
  }

  const getPublicUrl = (): string | undefined => {
    const clientName = getClientName()
    return getClientConfigs(clientName).publicUrl
  }

  const getDashboards = (): ClientDashboardConfig => {
    const clientName = getClientName()
    return getClientConfigs(clientName).dashboards
  }

  const getStaticDashboards = (): DashboardList => {
    const clientName = getClientName()
    return getClientConfigs(clientName).staticDashboards
  }

  const getSentimentCommentsIndicatorName = (): string => {
    const clientName = getClientName()
    return getClientConfigs(clientName).sentimentCommentsIndicatorName
  }

  const getDashboardConfig = (dashboard: string): DashboardConfigInfo => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return (
      clientConfigs.dashboards[dashboard] ?? clientConfigs.dashboards.default
    )
  }

  const getClientLogoSmall = (): any => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.logoSmall
  }

  const getClientLogo = (): any => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.logo
  }

  const getClientshipLogo = (): any => {
    return logoClientship
  }

  const getClientMenuDashboards = (): DASHBOARDS[] => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.menuDashboards
  }

  const getMetadataLoad = (): METADADATA_LOAD[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).metadata
  }

  const useCriticalMoments = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.CRITICAL_MOMENTS)
  }

  const useLogicalLocations = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.LOGICALS)
  }

  const useGeoLocations = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.GEOS)
  }

  const useClusters = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.CLUSTERS)
  }

  const useDynamicReports = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.DYNAMIC_REPORT_FIELDS)
  }

  const useWorkflows = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.WORKFLOWS)
  }

  const getConfigFilters = (): string[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).filters
  }

  const supportsInternationalization = () => {
    const clientName = getClientName()
    return getClientConfigs(clientName).supportsInternationalization
  }

  const includesFilter = (filterName: string): boolean => {
    const configFilters = getConfigFilters()
    return configFilters.includes(filterName)
  }

  const includesFilterDate = (): boolean => {
    return includesFilter(FILTER_TYPES.DATES)
  }

  const includesFilterGeo = (): boolean => {
    return includesFilter(FILTER_TYPES.GEOS)
  }

  const includesFilterLogical = (): boolean => {
    return includesFilter(FILTER_TYPES.LOGICALS)
  }

  const includesFilterCriticalMoment = (): boolean => {
    return includesFilter(FILTER_TYPES.CRITICAL_MOMENTS)
  }

  const includesFilterCluster = (): boolean => {
    return includesFilter(FILTER_TYPES.CLUSTERS)
  }

  const getMsExcelReportExt = (): string => {
    const clientName = getClientName()
    return getClientConfigs(clientName).msExcelReportExt
  }

  const getOmoikaneConfig = (): OmoikaneConfig => {
    const clientName = getClientName()
    return getClientConfigs(clientName).omoikane
  }

  const getFilterCriticalMoments = (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ): CriticalMoment[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).getFilterCriticalMoments(
      filtersData,
      userProfiles
    )
  }

  const getFilterCriticalMomentDefault = (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => {
    const clientName = getClientName()
    return getClientConfigs(clientName).getFilterCriticalMomentDefault(
      filtersData,
      userProfiles
    )
  }

  const getGeosRootId = (): number => {
    const clientName = getClientName()
    return getClientConfigs(clientName).geosRootId
  }

  const getLogicalsRootId = (): number => {
    const clientName = getClientName()
    return getClientConfigs(clientName).logicalsRootId
  }

  const getCriticalMomentsRootId = (): number => {
    const clientName = getClientName()
    return getClientConfigs(clientName).criticalMomentsRootId
  }

  const getCriticalMomentFilterType = (): 'tree' | 'select' => {
    const clientName = getClientName()
    return getClientConfigs(clientName).criticalMomentFilterType
  }


  const getFilterFromDateSubstract = () => {
    const clientName = getClientName()
    return getClientConfigs(clientName).filtersFromDateSubstract ?? 1
  }

  const getFilterToDateSubstract = () => {
    const clientName = getClientName()
    return getClientConfigs(clientName).filtersToDateSubstract ?? 0
  }

  const useBackDashboards = (): boolean => {
    const clientName = getClientName()
    return getClientConfigs(clientName).useBackDashboards ?? false
  }

  const getSurveyResponsesUrl = (): string => {
    const clientName = getClientName()
    return getClientConfigs(clientName).surveyResponsesUrl
  }

  return {
    // General
    getClientName,
    getVocBackendUrl,
    getLcmBackendUrl,
    getClientLogo,
    getClientLogoSmall,
    getClientshipLogo,
    getPublicUrl,
    supportsInternationalization,

    // Filters
    includesFilterDate,
    includesFilterGeo,
    includesFilterLogical,
    includesFilterCriticalMoment,
    includesFilterCluster,
    useCriticalMoments,
    useLogicalLocations,
    useGeoLocations,
    useClusters,
    useWorkflows,
    getGeosRootId,
    getLogicalsRootId,
    getFilterFromDateSubstract,
    getFilterToDateSubstract,
    getCriticalMomentsRootId,
    getCriticalMomentFilterType,
    getFilterCriticalMoments,
    getFilterCriticalMomentDefault,

    // Dashboard
    getClientMenuDashboards,
    getDashboards,
    getDashboardConfig,
    useBackDashboards,
    getStaticDashboards,
    getSentimentCommentsIndicatorName,

    // Reports
    useDynamicReports,
    getMsExcelReportExt,

    // Omoikane
    getOmoikaneConfig,

    // Survey Responses
    getSurveyResponsesUrl,
  }
}
