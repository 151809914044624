
import logoCaffenio from '@assets/logos/caffenio-logo.png'
import logoCaffenioSmall from '@assets/logos/caffenio-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments, getMitsubishiFilterCriticalMomentDefault, getMitsubishiFilterCriticalMoments } from '../dashboard/dashHelper'
import { caffenioStaticDashboards } from '../dashboard/caffenio/staticDashboards'

export const caffenioConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://caffenio-backend-voc.limetropy.com/api/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    lcmBackendUrl: 'https://caffenio-backend-core.limetropy.com/backend/core/api/ui/',
    publicUrl: '/voc',
    logoSmall: logoCaffenioSmall,
    logo: logoCaffenio,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 0,
    supportsInternationalization: false,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT],
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    msExcelReportExt: 'xls',
    geosRootId: 0,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    omoikane: {
        enabledTabs: [],
        indicators: [],
        sentiment: {
            indicators: {},
            properties: {
                sentiment: '',
                percentage: '',
                sentimentLocal: '',
                percentageLocal: ''
            }
        },
        customerJourney: {},
        cxRay: { indicators: {} },
        clusters: {
            counters: { indicator: 'survey-count' },
            detail: {
                indicators: []
            }
        },
    },
    useBackDashboards: true,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.caffenio,
        default: DASHBOARDS_CONFIGS.caffenio[DASHBOARDS.DEFAULT]
    },
    staticDashboards: caffenioStaticDashboards,
    surveyResponsesUrl: 'survey-responses'
}
