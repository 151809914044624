import { answeredMapper, getCommonWidget, invalidMapper } from '../dashHelper'
import { commonWidgets } from './commonWidgetsByd'

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  widgets: (filters: FiltersUI) => [
    //==== Venta
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [0, 0],
      size: [1, 2],
      render: (filters: FiltersUI) => {

        return (

          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
      indicator: 'VENTA_SATISFACCION',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      position: [1, 0],
      size: [1, 2],
      indicator: 'POSVENTA_NPS',
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper,
      position: [2, 0],
      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [3, 0],
      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
    }),
    //======== customer journey
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label:  'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      position: (filters: FiltersUI) => [0, 2,],
      //position: [0, 4],
      size: [4, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
    }),
    //==== Posventa
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      indicator: 'POSVENTA_SATISFACCION',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [0, 4,]
          : [0, 0]
      },

      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      indicator: 'POSVENTA_NPS',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [1, 4]
          : [1, 0]
      },
      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [2, 4]
          : [2, 0]
      },
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      // [Column, Row]
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [3, 4]
          : [3, 0]
      },

      size: [1, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
    }),
    //======== customer journey
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label:  'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'POSVENTA_CUSTOMER_JOURNEY',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [0, 6]
          : [0, 2]
      },

      //position: [0, 4],
      size: [4, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'bestGeoLocations',
      position: [0, 8],
      size: [2, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0))
        )
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsVenta',
      indicator: 'VENTA_SATISFACCION',
      position: [0, 4],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(1))
        )
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      indicator: 'POSVENTA_SATISFACCION',
      position: [0, 4],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(2))
        )
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'worstGeoLocations',
      position: [2, 8],
      size: [2, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0))
        )
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsVenta',
      indicator: 'VENTA_SATISFACCION',
      position: [2, 4],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(1))
        )
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [2, 4],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: 2,
    }),
    //==== sentiment analysis
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [0, 10]
          : [0, 6]
      },
      size: [4, 4],
      render: (filters: FiltersUI) => true,
    }),
    //==================Sentimientos positivos
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsVenta',
      position: [0, 14],
      size: [2, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0))
        )
      },
      extras: {
        resultType: 'top',
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsVenta',
      indicator: 'SENTIMENT',
      position: [0, 10],
      size: [2, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(1))
        )
      },
      extras: {
        resultType: 'top',
        trunc: "HALF_DOWN",
        topSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsPostventa',
      position: [0, 10],
      size: [2, 2],
      indicator: 'sentiment',
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(2))
        )
      },
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    //==================Sentimientos negativos
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsVenta',
      position: [2, 14],
      size: [2, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0))
        )
      },
      indicator: 'sentiment',
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsVenta',
      indicator: 'SENTIMENT',
      position: [2, 10],
      size: [2, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(1))
        )
      },
      extras: {
        resultType: 'bottom',
        trunc: "HALF_UP",
        bottomSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsPostventa',
      position: [2, 10],
      size: [2, 2],
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(2))
        )
      },
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),

    //==== ranking sentimientos

  ],
} as DasboardConfig
